import React, { useEffect, useState, lazy, Suspense } from "react";
import Routers from "./routers/Routers";
import { Box } from "@chakra-ui/react";
import "./App.css";
import Navbar from "./Components/Header/Navbar";
import UniversalLoader from "./Components/pages/UniversalLoader";

// Lazy load the Footer component
const Footer = lazy(() => import("./Components/Footer/Footer"));

function App() {
 const [isLoading, setIsLoading] = useState(true);

 useEffect(() => {
  // Simulate a 2-second loading phase
  const timer = setTimeout(() => {
   setIsLoading(false);
  }, 1000);

  // Clear the timer to avoid memory leaks
  return () => clearTimeout(timer);
 }, []);

 return (
  <Box>
   {/* Show UniversalLoader while loading */}
   {isLoading && <UniversalLoader />}

   {/* Render Navbar and Routers when not loading */}
   {!isLoading && <Navbar />}
   {!isLoading && <Routers />}

   {/* Lazy load Footer after UI has rendered */}
   {!isLoading && (
    <Suspense
    //  fallback={<div>Loading footer...</div>}
    >
     <Footer />
    </Suspense>
   )}
  </Box>
 );
}

export default App;
