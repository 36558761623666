import React, { useEffect, useState } from "react";
import {
 Box,
 Image,
 Text,
 Button,
 VStack,
 HStack,
 Divider,
 Heading,
 Flex,
 Radio,
 RadioGroup,
 Stack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
 applyCoupon,
 fetchCart,
 fetchCouponCodes,
} from "../../redux/cartSlice";
import { useNavigate } from "react-router-dom";

const CartCard = ({
 cartItems = [],
 handleQuantityChange,
 handleRemove,
 coupon = [],
}) => {
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const { cart, couponCodes } = useSelector((state) => state.cart);
 const { user } = useSelector((state) => state.auth);

 const [visibleItems, setVisibleItems] = useState(2);
 const [selectedCoupon, setSelectedCoupon] = useState(cart?.couponCode || "");

 const items = Array.isArray(cartItems) ? cartItems : [];
 const totalAmount = items.reduce(
  (acc, item) => acc + item.price * (item.quantity || 1),
  0
 );

 useEffect(() => {
  if (coupon.length === 0) {
   dispatch(fetchCouponCodes());
  }
  if (cart?.couponCode) {
   setSelectedCoupon(cart.couponCode);
  }
 }, [dispatch, coupon.length, cart?.couponCode]);

 const isCouponApplicable = (coupon) => {
  if (!coupon || !coupon.subCategory || !coupon.subCategory._id) return false;

  const isSubCategoryMatch = items.some(
   (item) => item.subCategoryId === coupon.subCategory._id
  );
  const isNotExpired = new Date(coupon.expiryDate) > new Date();
  return isSubCategoryMatch && isNotExpired;
 };

 const handleApplyCoupon = (couponCode) => {
  setSelectedCoupon(couponCode);
  const userId = user?._id;
  dispatch(applyCoupon({ userId, couponCode }));
 };

 const handleToggle = () => {
  setVisibleItems((prev) => (prev < items.length ? items.length : 2));
 };
 console.log("couponCodes:", couponCodes.length);
 useEffect(() => {
  dispatch(fetchCart(user?._id));
  // dispatch(fetchCouponCodes(user?._id));
 }, []);

 return (
  <Box
   bg="white"
   p={6}
   borderWidth={1}
   borderRadius="lg"
   shadow="lg"
   maxW="lg"
   mx="auto"
  >
   <Box mb={6}>
    <Heading as="h3" size="md" textAlign="center">
     Your Cart
    </Heading>
   </Box>

   {/* Cart Items */}
   <VStack spacing={4} align="stretch" mb={6}>
    {items.length === 0 ? (
     <Text textAlign="center" fontSize="lg" color="gray.500">
      No items in cart
     </Text>
    ) : (
     items.slice(0, visibleItems).map((item) => (
      <VStack
       key={item._id}
       p={4}
       borderWidth={1}
       borderRadius="md"
       shadow="sm"
       bg="gray.50"
      >
       <HStack spacing={4} w="100%">
        <VStack align="start" spacing={2} flex="2">
         <Text fontWeight="bold" fontSize="lg">
          Product: {item?.productDetails?.productName}
         </Text>
         <Text color="gray.600">Price: ₹{item.price}</Text>

         {/* Quantity controls */}
         {item?.type !== "Product" && (
          <HStack spacing={3} mt={2}>
           <Button size="sm" onClick={() => handleQuantityChange(item._id, -1)}>
            -
           </Button>
           <Text fontWeight="bold">{item.quantity}</Text>
           <Button size="sm" onClick={() => handleQuantityChange(item._id, 1)}>
            +
           </Button>
          </HStack>
         )}

         <Button
          variant="link"
          colorScheme="red"
          size="sm"
          onClick={() => handleRemove(item)}
         >
          Remove
         </Button>
        </VStack>
        <Image
         boxSize="100px"
         src={
          item?.type === "Product"
           ? item?.productDetails?.productImage
           : item?.productDetails?.image
         }
        />
       </HStack>
      </VStack>
     ))
    )}
    {items.length > 2 && (
     <Button
      colorScheme="blue"
      size="sm"
      variant="outline"
      onClick={handleToggle}
     >
      {visibleItems < items.length ? "Show More" : "Show Less"}
     </Button>
    )}
   </VStack>

   {/* Coupon Section */}
   <Box>
    <Divider mb={4} />
    <VStack align="stretch" spacing={2}>
     <Text cursor={"pointer"} fontWeight="bold">
      Available Coupons ({couponCodes?.length}) 👆
     </Text>
     <Flex justify="space-between" flexWrap="wrap">
      {coupon?.map((item) => {
       return isCouponApplicable(item) ? (
        <Box
         key={item._id}
         p={2}
         borderWidth={1}
         borderRadius="md"
         bgColor="blue.700"
         color="white"
         w="100%"
        >
         <RadioGroup onChange={setSelectedCoupon} value={selectedCoupon}>
          <Stack direction="row" align="center" justify="space-between">
           <Radio value={item.code}>{item.title}</Radio>
           {selectedCoupon === item.code && (
            <Button
             colorScheme="blue"
             onClick={() => handleApplyCoupon(item.code)}
            >
             Apply
            </Button>
           )}
          </Stack>
         </RadioGroup>
         <Text>Code: {item.code}</Text>
         <Text mt={-2}>Discount: {item.discountPercent}%</Text>
        </Box>
       ) : null;
      })}
     </Flex>

     {/* Cart Summary */}
     <Flex justify="space-between">
      <Text fontWeight="bold">Subtotal:</Text>
      <Text>₹{totalAmount}</Text>
     </Flex>
     <Flex justify="space-between">
      <Text fontWeight="bold">Discount:</Text>
      <Text>- ₹{cart?.couponDiscount || 0}</Text>
     </Flex>
     <Flex justify="space-between" fontWeight="bold" fontSize="lg">
      <Text>Total:</Text>
      <Text>₹{cart?.finalPrice?.toFixed(2)}</Text>
     </Flex>

     {/* Place Order Button */}
     <Button
      colorScheme="red"
      w="100%"
      mt={4}
      size="lg"
      onClick={() => navigate("/slot-booking")}
     >
      Place Order
     </Button>
    </VStack>
   </Box>
  </Box>
 );
};

export default CartCard;
