import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./axiosInstance";
// import axiosInstance from axiosInstance";

// Async thunk for sending OTP
export const sendOtp = createAsyncThunk(
 "auth/sendOtp",

 async ({ mobile, name }) => {
  console.log("name:", name);
  const response = await axiosInstance.post("/users/send-otp", {
   phoneNumber: mobile,
   name: name,
  });

  return response.data.otp;
 }
);

// Async thunk for verifying OTP and fetching user details
export const verifyOtp = createAsyncThunk(
 "auth/verifyOtp",
 async ({ mobile, otp }, { rejectWithValue }) => {
  try {
   // Verify OTP
   const otpResponse = await axiosInstance.post("/users/verify-otp", {
    phoneNumber: mobile,
    otpCode: otp,
   });
   const token = otpResponse.data.token;

   // Fetch user details using the token
   const userResponse = await axiosInstance.get("/users/user/me", {
    headers: { Authorization: `Bearer ${token}` },
   });

   const userData = userResponse.data.data;
   console.log("userData:", userData.currentCar);

   return { token, userData };
  } catch (error) {
   return rejectWithValue(
    error.response?.data?.message || "Something went wrong"
   );
  }
 }
);

// Async thunk for fetching user data
export const fetchUserData = createAsyncThunk(
 "auth/fetchUserData",
 async (token, { rejectWithValue }) => {
  try {
   const response = await axiosInstance.get("/users/user/me", {
    headers: { Authorization: `Bearer ${token}` },
   });
   localStorage.removeItem("userDetails");
   const userDetails = response.data.data;
   console.log("response:", response.data.data);

   localStorage.setItem("userDetails", JSON.stringify(userDetails));

   console.log("response:", response.data.data);

   return response.data.data;
  } catch (error) {
   return rejectWithValue(
    error.response?.data?.message || "Failed to fetch user data"
   );
  }
 }
);
export const logoutUser = createAsyncThunk(
 "auth/logoutUser",
 async (_, { rejectWithValue }) => {
  try {
   // Clear localStorage directly
   localStorage.clear();
   window.location.reload();

   return true; // Indicate successful logout
  } catch (error) {
   return rejectWithValue("Failed to logout"); // Handle unexpected errors
  }
 }
);

const authSlice = createSlice({
 name: "auth",
 initialState: {
  user: JSON.parse(localStorage.getItem("userDetails")) || null,
  token: JSON.parse(localStorage.getItem("authToken")) || null,
  isAuth: !!localStorage.getItem("isAuth"),
  otp: null,
  selectedCar: JSON.parse(localStorage.getItem("MyCarData")) || null, // Load selected car from localStorage
  loading: false,
  error: null,
 },
 reducers: {
  logout: (state) => {
   state.user = null;
   state.token = null;
   state.isAuth = false;
   state.otp = null;
   localStorage.clear();
   localStorage.removeItem("authToken");
   localStorage.removeItem("isAuth");
   window.location.reload();
  },
  setUser: (state, action) => {
   const { user, token } = action.payload;
   state.user = user;
   state.token = token;
   state.isAuth = true;
   localStorage.setItem("userDetails", JSON.stringify(user));
   localStorage.setItem("authToken", JSON.stringify(token));
   localStorage.setItem("isAuth", true);
  },
 },
 extraReducers: (builder) => {
  builder
   .addCase(sendOtp.pending, (state) => {
    state.loading = true;
   })
   .addCase(sendOtp.fulfilled, (state, action) => {
    state.loading = false;
    state.otp = action.payload;
    state.error = null;
   })
   .addCase(sendOtp.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   })
   .addCase(verifyOtp.pending, (state) => {
    state.loading = true;
   })
   .addCase(verifyOtp.fulfilled, (state, action) => {
    const { token, userData } = action.payload;
    state.loading = false;
    state.user = userData;
    state.token = token;
    state.isAuth = true;
    state.error = null;

    // Update Redux state and localStorage
    if (userData.currentCar !== undefined) {
     state.selectedCar = userData.currentCar; // Update state
     localStorage.setItem("MyCarData", JSON.stringify(userData.currentCar)); // Save to localStorage
     window.location.reload();
    }

    // Save other user details and token
    localStorage.setItem("userDetails", JSON.stringify(userData));
    localStorage.setItem("authToken", JSON.stringify(token));
    localStorage.setItem("isAuth", true);
   })
   .addCase(verifyOtp.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload || action.error.message;
   })
   .addCase(fetchUserData.pending, (state) => {
    state.loading = true;
    state.error = null;
   })
   .addCase(fetchUserData.fulfilled, (state, action) => {
    state.loading = false;
    state.user = action.payload;
   })
   .addCase(fetchUserData.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   })
   .addCase(logoutUser.fulfilled, (state) => {
    state.user = null;
    state.token = null;
    state.isAuth = false;
    state.otp = null;
    localStorage.clear();
   })
   .addCase(logoutUser.rejected, (state, action) => {
    state.error = action.payload || "Logout failed";
   });
 },
});

export const { logout, setUser } = authSlice.actions;

export default authSlice.reducer;

// // Import necessary libraries
// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";

// // Async thunk for sending OTP
// export const sendOtp = createAsyncThunk("auth/sendOtp", async ({ mobile }) => {
//  const response = await axios.post("https://app-api.carexpert.org.in/api/users/send-otp", {
//   phoneNumber: mobile,
//  });
//  return response.data.otp; // Assuming response.data contains otp
// });

// // Async thunk for verifying OTP and fetching user details
// export const verifyOtp = createAsyncThunk(
//  "auth/verifyOtp",
//  async ({ mobile, otp }, { rejectWithValue }) => {
//   try {
//    // Verify OTP
//    const otpResponse = await axios.post(
//     "https://app-api.carexpert.org.in/api/users/verify-otp",
//     {
//      phoneNumber: mobile,
//      otpCode: otp,
//     }
//    );
//    const token = otpResponse.data.token; // Assuming response.data.token exists

//    // Fetch user details using the token
//    const userResponse = await axios.get(
//     "https://app-api.carexpert.org.in/api/users/user/me",
//     {
//      headers: { Authorization: `Bearer ${token}` },
//     }
//    );
//    const userData = userResponse.data.data; // Assuming response.data contains user details

//    // Return token and user data
//    return { token, userData };
//   } catch (error) {
//    return rejectWithValue(
//     error.response?.data?.message || "Something went wrong"
//    );
//   }
//  }
// );
// export const fetchUserData = createAsyncThunk(
//  "user/fetchUserData",
//  async (token, { rejectWithValue }) => {
//   try {
//    console.log("token:", token);
//    const response = await axios.get("https://app-api.carexpert.org.in/api/users/user/me", {
//     headers: { Authorization: `Bearer ${token}` },
//    });
//    return response.data; // Return user data if the request is successful
//   } catch (error) {
//    // Reject with error message for the extraReducer
//    return rejectWithValue(
//     error.response?.data?.message || "Failed to fetch user data"
//    );
//   }
//  }
// );
// // Create a slice for managing authentication
// const authSlice = createSlice({
//  name: "auth",
//  initialState: {
//   user: JSON.parse(localStorage.getItem("userDetails")) || null, // Persist user details in localStorage
//   token: JSON.parse(localStorage.getItem("authToken")) || null, // Persist token in localStorage
//   isAuth: !!localStorage.getItem("isAuth"), // Determine auth status based on localStorage
//   otp: null, // To store the OTP sent to the user
//   loading: false,
//   error: null,
//  },
//  reducers: {
//   logout: (state) => {
//    state.user = null;
//    state.token = null;
//    state.isAuth = false;
//    state.otp = null; // Reset OTP on logout
//    localStorage.removeItem("userDetails");
//    localStorage.removeItem("authToken");
//    localStorage.removeItem("isAuth");
//   },
//   setUser: (state, action) => {
//    const { user, token } = action.payload;
//    state.user = user;
//    state.token = token;
//    state.isAuth = true;
//    localStorage.setItem("userDetails", JSON.stringify(user));
//    localStorage.setItem("authToken", JSON.stringify(token));
//    localStorage.setItem("isAuth", true);
//   },
//  },
//  extraReducers: (builder) => {
//   // Handle OTP sending process
//   builder
//    .addCase(sendOtp.pending, (state) => {
//     state.loading = true;
//    })
//    .addCase(sendOtp.fulfilled, (state, action) => {
//     state.loading = false;
//     state.otp = action.payload;
//     state.error = null;
//    })
//    .addCase(sendOtp.rejected, (state, action) => {
//     state.loading = false;
//     state.error = action.error.message;
//    });

//   // Handle OTP verification and fetching user details
//   builder
//    .addCase(verifyOtp.pending, (state) => {
//     state.loading = true;
//    })
//    .addCase(verifyOtp.fulfilled, (state, action) => {
//     const { token, userData } = action.payload;
//     state.loading = false;
//     state.user = userData;
//     state.token = token;
//     state.isAuth = true;
//     state.error = null;
//     // Persist user details and token in localStorage
//     localStorage.setItem("userDetails", JSON.stringify(userData));
//     localStorage.setItem("authToken", JSON.stringify(token));
//     localStorage.setItem("isAuth", true);
//    })
//    .addCase(verifyOtp.rejected, (state, action) => {
//     state.loading = false;
//     state.error = action.payload || action.error.message;
//    })
//    .addCase(fetchUserData.pending, (state) => {
//     state.loading = true;
//     state.error = null; // Clear any previous error
//    })
//    // Fulfilled state
//    .addCase(fetchUserData.fulfilled, (state, action) => {
//     state.loading = false;
//     state.user = action.payload; // Update state with user data
//    })
//    // Rejected state
//    .addCase(fetchUserData.rejected, (state, action) => {
//     state.loading = false;
//     state.error = action.payload; // Set error message
//    });
//  },
// });

// export const { logout, setUser } = authSlice.actions;

// export default authSlice.reducer;

// // // Import necessary libraries
// // import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// // import axios from "axios";

// // // Async thunk for sending OTP
// // export const sendOtp = createAsyncThunk("auth/sendOtp", async ({ mobile }) => {
// //  const response = await axios.post("https://app-api.carexpert.org.in/api/users/send-otp", {
// //   phoneNumber: mobile,
// //  });
// //  return response.data.otp; // Assuming response.data contains otp
// // });

// // // Async thunk for verifying OTP
// // export const verifyOtp = createAsyncThunk(
// //  "auth/verifyOtp",
// //  async ({ mobile, otp }) => {
// //   const response = await axios.post(
// //    "https://app-api.carexpert.org.in/api/users/verify-otp",
// //    {
// //     phoneNumber: mobile,
// //     otpCode: otp,
// //    }
// //   );
// //   return response.data.token; // Assuming the token is in response.data.token
// //  }
// // );

// // //  const handleSubmitOtp = async () => {
// // //   // Dispatch the verifyOtp action with the correct object
// // //   const response = await dispatch(verifyOtp({ mobile, otp: otpval }));
// // //   if (response.type == "auth/verifyOtp/fulfilled") {
// // //    await dispatch(fetchUserDetails(response.payload));
// // //   }

// // // Async thunk for fetching user details
// // export const fetchUserDetails = createAsyncThunk(
// //  "auth/fetchUserDetails",
// //  async (token) => {
// //   const response = await axios.get(
// //    "https://app-api.carexpert.org.in/api/users/user/me",
// //    {
// //     headers: { Authorization: `Bearer ${token}` },
// //    }
// //   );
// //   return response.data.data; // Assuming response.data contains user details
// //  }
// // );

// // // Create a slice for managing authentication
// // const authSlice = createSlice({
// //  name: "auth",
// //  initialState: {
// //   user: JSON.parse(localStorage.getItem("userDetails")) || null, // To store the logged-in user's data (if any)
// //   otp: null, // To store the OTP sent to the user
// //   token: null, // To store the auth token (if using token-based authentication)
// //   userDetails: null, // To store user details fetched after authentication
// //   isAuth: false, // Indicates if the user is authenticated
// //   loading: false,
// //   error: null,
// //  },
// //  reducers: {
// //   logout: (state) => {
// //    state.user = null;
// //    state.token = null;
// //    state.userDetails = null;
// //    state.isAuth = false;
// //    state.otp = null; // Reset OTP on logout
// //    localStorage.removeItem("isAuth");
// //    localStorage.removeItem("userDetails");
// //   },
// //  },
// //  extraReducers: (builder) => {
// //   // Handle OTP sending process
// //   builder
// //    .addCase(sendOtp.pending, (state) => {
// //     state.loading = true;
// //    })
// //    .addCase(sendOtp.fulfilled, (state, action) => {
// //     state.loading = false;
// //     state.otp = action.payload;
// //     state.error = null;
// //    })
// //    .addCase(sendOtp.rejected, (state, action) => {
// //     state.loading = false;
// //     state.error = action.error.message;
// //    });

// //   // Handle OTP verification process
// //   builder
// //    .addCase(verifyOtp.pending, (state) => {
// //     state.loading = true;
// //    })
// //    .addCase(verifyOtp.fulfilled, (state, action) => {
// //     state.loading = false;
// //     state.token = action.payload;
// //     state.isAuth = true; // Set isAuth to true
// //     state.error = null;
// //     localStorage.setItem("isAuth", true); // Save authentication status
// //    })
// //    .addCase(verifyOtp.rejected, (state, action) => {
// //     state.loading = false;
// //     state.error = action.error.message;
// //    });

// //   // Handle fetching user details
// //   builder
// //    .addCase(fetchUserDetails.pending, (state) => {
// //     state.loading = true;
// //    })
// //    .addCase(fetchUserDetails.fulfilled, (state, action) => {
// //     state.loading = false;
// //     state.userDetails = action.payload;
// //     state.error = null;
// //     localStorage.setItem("userDetails", JSON.stringify(action.payload));
// //    })
// //    .addCase(fetchUserDetails.rejected, (state, action) => {
// //     state.loading = false;
// //     state.error = action.error.message;
// //    });
// //  },
// // });

// // export const { logout } = authSlice.actions;

// // export default authSlice.reducer;
