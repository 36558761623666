// import React, { useEffect, useState, useRef } from "react";
// import {
//  Box,
//  Button,
//  Flex,
//  Image,
//  SimpleGrid,
//  VStack,
//  Heading,
//  Text,
//  Icon,
//  useBreakpointValue,
// } from "@chakra-ui/react";
// import { CheckCircleIcon } from "@chakra-ui/icons";

// const ListingPage = ({
//  expandedCard,
//  toggleShowMore,
//  handleAddToCart,
//  isProductInCart,
// }) => {
//  function groupBySubCategory(data) {
//   const subCategoryMap = new Map();

//   data.forEach((item) => {
//    const subCategoryId = item.subCategoryId._id;
//    const subCategoryName = item.subCategoryId.subCategoryName;

//    if (!subCategoryMap.has(subCategoryId)) {
//     subCategoryMap.set(subCategoryId, {
//      subCategoryName,
//      products: [],
//     });
//    }

//    subCategoryMap.get(subCategoryId).products.push(item);
//   });

//   return Array.from(subCategoryMap.values());
//  }

//  const groupedData = groupBySubCategory(data);

//  const [activeTab, setActiveTab] = useState(groupedData[0]?.subCategoryName);
//  const sectionRefs = useRef([]);
//  const tabContainerRef = useRef(null);
//  const isMobile = useBreakpointValue({ base: true, md: false });

//  let previousScrollY = window.scrollY; // Track previous scroll position

//  const scrollToSection = (index) => {
//   const section = sectionRefs.current[index];
//   if (section) {
//    const offset = 50; // Margin-top offset
//    const sectionTop = section.getBoundingClientRect().top + window.scrollY;

//    // Check if scrolling up
//    const isScrollingUp = previousScrollY > window.scrollY;

//    window.scrollTo({
//     top: isScrollingUp ? sectionTop - offset : sectionTop, // Apply offset only when scrolling up
//     behavior: "smooth",
//    });

//    previousScrollY = window.scrollY; // Update previous scroll position
//   }

//   // Scroll the tab bar to make the active tab visible
//   const tabButton = tabContainerRef.current?.children[index];
//   if (tabButton) {
//    const tabContainer = tabContainerRef.current;
//    const buttonLeft = tabButton.offsetLeft;
//    const buttonRight = tabButton.offsetLeft + tabButton.offsetWidth;

//    if (buttonLeft < tabContainer.scrollLeft) {
//     tabContainer.scrollTo({ left: buttonLeft, behavior: "smooth" });
//    } else if (
//     buttonRight >
//     tabContainer.scrollLeft + tabContainer.clientWidth
//    ) {
//     tabContainer.scrollTo({
//      left: buttonRight - tabContainer.clientWidth,
//      behavior: "smooth",
//     });
//    }
//   }

//   setActiveTab(groupedData[index].subCategoryName);
//  };

//  useEffect(() => {
//   const observer = new IntersectionObserver(
//    (entries) => {
//     // Find the section closest to the viewport center
//     const visibleSections = entries.filter((entry) => entry.isIntersecting);
//     if (visibleSections.length > 0) {
//      const closestSection = visibleSections.reduce((closest, entry) => {
//       const entryTop = Math.abs(entry.boundingClientRect.top);
//       const closestTop = Math.abs(closest.boundingClientRect.top);
//       return entryTop < closestTop ? entry : closest;
//      });

//      const visibleSubCategory =
//       closestSection.target.getAttribute("data-subcategory");
//      const index = groupedData.findIndex(
//       (item) => item.subCategoryName === visibleSubCategory
//      );

//      setActiveTab(groupedData[index].subCategoryName);
//      scrollToSection(index);
//     }
//    },
//    { root: null, threshold: 0.5 } // Use 50% threshold for more accurate visibility detection
//   );

//   sectionRefs.current.forEach((section) => {
//    if (section) observer.observe(section);
//   });

//   return () => observer.disconnect();
//  }, [groupedData]);

//  return (
//   <Flex direction="column" minH="100vh">
//    {/* Subcategory Tabs */}
//    <Box
//     bg="#fff"
//     position="sticky"
//     top="0"
//     zIndex="100"
//     p="10px"
//     boxShadow="md"
//     overflowX="auto"
//    >
//     <Flex
//      ref={tabContainerRef}
//      gap="10px"
//      align="center"
//      overflowX="auto"
//      pb={isMobile ? "10px" : "0"}
//     >
//      {groupedData.map((item, index) => (
//       <Button
//        key={item.subCategoryName}
//        onClick={() => scrollToSection(index)}
//        bg={activeTab === item.subCategoryName ? "blue.500" : "gray.300"}
//        color={activeTab === item.subCategoryName ? "white" : "black"}
//        _hover={{ bg: "blue.400" }}
//        borderRadius="md"
//        whiteSpace="nowrap"
//        flexShrink={0}
//       >
//        {item.subCategoryName}
//       </Button>
//      ))}
//     </Flex>
//    </Box>

//    {/* Product Sections */}
//    <Box flex="1" overflowY="auto">
//     {groupedData.map((item, index) => (
//      <Box
//       key={item.subCategoryName}
//       ref={(el) => (sectionRefs.current[index] = el)}
//       data-subcategory={item.subCategoryName}
//       p={4}
//       bg={activeTab === item.subCategoryName ? "gray.100" : "white"}
//       borderBottom="1px solid #E0E0E0"
//      >
//       <Heading size="md" mb={4}>
//        {item.subCategoryName}
//       </Heading>
//       {/* <SimpleGrid columns={1} spacing={4}>
//        {item.products.map((product) => (
//         <Flex
//          key={product._id}
//          direction={{ base: "column", md: "row" }}
//          borderWidth="1px"
//          borderRadius="md"
//          boxShadow="sm"
//          p={4}
//          bg="#f9f9f9"
//          align="stretch"
//         >
//          <Image
//           src={product.productImage || "https://via.placeholder.com/150"}
//           alt={product.productName}
//           boxSize={{ base: "100px", md: "150px" }}
//           objectFit="cover"
//           borderRadius="md"
//          />
//          <Box flex="1" px={4}>
//           <Heading size="sm" fontWeight="bold" mb={2}>
//            {product.productName}
//           </Heading>
//           <SimpleGrid columns={2} spacing={2}>
//            {product.highlights?.map((highlight, index) => (
//             <Text key={index} fontSize="sm" color="gray.700">
//              <Icon as={CheckCircleIcon} color="green.500" mr={2} />
//              {highlight.text}
//             </Text>
//            ))}
//           </SimpleGrid>
//          </Box>
//          <VStack align="flex-end" spacing={2}>
//           <Text fontSize="lg" fontWeight="bold" color="red.600">
//            ₹{product.dummyPriceActual}
//           </Text>
//           <Button colorScheme="red" size="sm">
//            Add To Cart
//           </Button>
//          </VStack>
//         </Flex>
//        ))}
//       </SimpleGrid> */}
//       <SimpleGrid columns={1} spacing={2}>
//        {item.products.map((product) => (
//         <Flex
//          key={product._id}
//          minH="160px"
//          direction={{ base: "column", md: "row" }} // Responsive layout
//          borderRadius="md"
//          borderWidth="1px"
//          sx={{
//           boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Light shadow
//          }}
//          p={4}
//          bg="#f9f9f9"
//          align="stretch"
//         >
//          <Image
//           src={
//            product.productImage ||
//            "https://m.media-amazon.com/images/I/51-6Y+fNmAL._SX522_.jpg"
//           }
//           alt={product.productName}
//           boxSize={{ base: "100px", md: "80px" }} // Full width for mobile
//           objectFit="cover"
//           borderRadius="md"
//           bg="white"
//           mb={{ base: 4, md: 0 }} // Add margin on mobile
//          />

//          <Box flex="1" px={4}>
//           <Heading size="sm" fontWeight="bold" mb={2}>
//            {product.productName || "Basic Car Service"}
//           </Heading>

//           <SimpleGrid columns={{ base: 2, md: 2, lg: 3 }} spacing={2} w="100%">
//            {product.highlights?.map((highlight, index) => (
//             <Text key={index} fontSize="sm" color="gray.700">
//              <Icon as={CheckCircleIcon} color="red.500" mr={2} />
//              {highlight.text || "Highlight"}
//             </Text>
//            ))}

//            {/* Expandable Section */}
//            {expandedCard === product._id && (
//             <>
//              {product.additionalServices?.map((highlight, index) => (
//               <Text key={index} fontSize="sm" color="gray.700">
//                <Icon as={CheckCircleIcon} color="red.500" mr={2} />
//                {highlight || "Additional Service"}
//               </Text>
//              ))}
//              {product.includedService?.map((serviceGroup) =>
//               serviceGroup.services.map((service) => (
//                <Text key={service._id} fontSize="sm" color="gray.700">
//                 <Icon as={CheckCircleIcon} color="red.500" mr={2} />
//                 {service.title}
//                </Text>
//               ))
//              )}
//             </>
//            )}
//           </SimpleGrid>

//           <Button
//            size="sm"
//            mt={2}
//            onClick={() => toggleShowMore(product._id)}
//            colorScheme="blue"
//            variant="link"
//           >
//            {expandedCard === product._id ? "Show Less" : "Show More"}
//           </Button>
//          </Box>

//          <VStack
//           align="stretch"
//           spacing={2}
//           mt={{ base: 4, md: 0 }}
//           textAlign={{ base: "left", md: "right" }}
//          >
//           <Text fontSize="lg" fontWeight="bold" color="red.600">
//            ₹{product.dummyPriceActual || "2497"}
//           </Text>
//           <Button
//            colorScheme="red"
//            size="sm"
//            px={6}
//            _hover={{ bg: "red.600" }}
//            onClick={() => handleAddToCart(product, "Product")}
//            isDisabled={isProductInCart(product?._id)}
//           >
//            {isProductInCart(product?._id) ? "Already in Cart" : "Add To Cart +"}
//           </Button>
//          </VStack>
//         </Flex>
//        ))}
//       </SimpleGrid>
//      </Box>
//     ))}
//    </Box>

//    {/* Footer */}
//    <Box bg="blue.800" color="white" py={6} px={4}>
//     <Text textAlign="center">Footer Content Here</Text>
//    </Box>
//   </Flex>
//  );
// };

// export default ListingPage;
import React, { useEffect, useState, useRef } from "react";
import {
 Box,
 Button,
 Flex,
 Image,
 SimpleGrid,
 VStack,
 Heading,
 Text,
 Icon,
 useBreakpointValue,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

const ListingPage = ({
 // Make sure 'data' is passed or default to empty
 expandedCard,
 toggleShowMore,
 handleAddToCart,
 isProductInCart,
}) => {
 function groupBySubCategory(data) {
  const subCategoryMap = new Map();

  data.forEach((item) => {
   const subCategoryId = item.subCategoryId._id;
   const subCategoryName = item.subCategoryId.subCategoryName;

   if (!subCategoryMap.has(subCategoryId)) {
    subCategoryMap.set(subCategoryId, {
     subCategoryName,
     products: [],
    });
   }

   subCategoryMap.get(subCategoryId).products.push(item);
  });

  return Array.from(subCategoryMap.values());
 }

 const groupedData = groupBySubCategory(data);

 const [activeTab, setActiveTab] = useState(groupedData[0]?.subCategoryName);

 // Refs for each section
 const sectionRefs = useRef([]);
 // Ref for the tabs container (for horizontal scrolling)
 const tabContainerRef = useRef(null);

 // If needed, track previous scroll for special behavior
 // let previousScrollY = window.scrollY;

 const isMobile = useBreakpointValue({ base: true, md: false });

 /**
  * Scroll to the section when a tab is clicked
  */
 const scrollToSection = (index) => {
  const section = sectionRefs.current[index];
  if (section) {
   // Optional offset
   const offset = 50;
   const sectionTop = section.getBoundingClientRect().top + window.scrollY;

   // Scroll smoothly to the section
   window.scrollTo({
    top: sectionTop - offset,
    behavior: "smooth",
   });
  }

  // Make sure the clicked tab is visible in the tab container
  const tabButton = tabContainerRef.current?.children[index];
  if (tabButton) {
   const tabContainer = tabContainerRef.current;
   const buttonLeft = tabButton.offsetLeft;
   const buttonRight = tabButton.offsetLeft + tabButton.offsetWidth;

   // Scroll horizontally if needed
   if (buttonLeft < tabContainer.scrollLeft) {
    tabContainer.scrollTo({ left: buttonLeft, behavior: "smooth" });
   } else if (
    buttonRight >
    tabContainer.scrollLeft + tabContainer.clientWidth
   ) {
    tabContainer.scrollTo({
     left: buttonRight - tabContainer.clientWidth,
     behavior: "smooth",
    });
   }
  }

  // Update active tab right away
  setActiveTab(groupedData[index].subCategoryName);
 };

 /**
  * Observe which section is in view as user scrolls.
  * We ONLY update `activeTab`; we do NOT call `scrollToSection`
  * or do another programmatic scroll, to avoid the scroll-jump loop.
  */
 useEffect(() => {
  const observer = new IntersectionObserver(
   (entries) => {
    // Find the entry that is most in view
    const visibleSections = entries.filter((entry) => entry.isIntersecting);
    if (visibleSections.length > 0) {
     // Pick the one closest to the top
     const closestSection = visibleSections.reduce((closest, entry) => {
      const entryTop = Math.abs(entry.boundingClientRect.top);
      const closestTop = Math.abs(closest.boundingClientRect.top);
      return entryTop < closestTop ? entry : closest;
     });

     const visibleSubCategory =
      closestSection.target.getAttribute("data-subcategory");

     const index = groupedData.findIndex(
      (item) => item.subCategoryName === visibleSubCategory
     );

     // **Do NOT** scroll here; just update the active tab
     setActiveTab(groupedData[index].subCategoryName);
    }
   },
   {
    root: null,
    threshold: 0.5, // adjust threshold as needed
   }
  );

  sectionRefs.current.forEach((section) => {
   if (section) observer.observe(section);
  });

  return () => observer.disconnect();
 }, [groupedData]);
 // 1) Add/keep your IntersectionObserver effect that sets `activeTab`
 // 2) Then add this effect to scroll horizontally whenever `activeTab` changes

 useEffect(() => {
  // Make sure we have the ref and groupedData
  if (!tabContainerRef.current || !groupedData?.length) return;

  // Find the index for the currently active subCategory
  const index = groupedData.findIndex(
   (item) => item.subCategoryName === activeTab
  );
  if (index === -1) return;

  // Grab the corresponding button in the tab container
  const tabButton = tabContainerRef.current.children[index];
  if (!tabButton) return;

  const container = tabContainerRef.current;
  const buttonLeft = tabButton.offsetLeft;
  const buttonRight = buttonLeft + tabButton.offsetWidth;

  // If the button is off-screen to the left, scroll to it
  if (buttonLeft < container.scrollLeft) {
   container.scrollTo({ left: buttonLeft, behavior: "smooth" });
  }
  // If the button is off-screen to the right, scroll so it's in view
  else if (buttonRight > container.scrollLeft + container.clientWidth) {
   container.scrollTo({
    left: buttonRight - container.clientWidth,
    behavior: "smooth",
   });
  }
 }, [activeTab, groupedData]);
 //  const handleAddToCart = () => {
 //   console.log("first");
 //  };
 return (
  <Flex direction="column" minH="100vh">
   {/* Subcategory Tabs */}
   <Box
    bg="#fff"
    position="sticky"
    top="0"
    zIndex="100"
    p="10px"
    boxShadow="md"
    overflowX="auto"
   >
    <Flex
     ref={tabContainerRef}
     gap="10px"
     align="center"
     overflowX="auto"
     pb={isMobile ? "10px" : "0"}
    >
     {groupedData.map((item, index) => (
      <Button
       key={item.subCategoryName}
       onClick={() => scrollToSection(index)}
       bg={activeTab === item.subCategoryName ? "blue.500" : "gray.300"}
       color={activeTab === item.subCategoryName ? "white" : "black"}
       _hover={{ bg: "blue.400" }}
       borderRadius="md"
       whiteSpace="nowrap"
       flexShrink={0}
      >
       {item.subCategoryName}
      </Button>
     ))}
    </Flex>
   </Box>

   {/* Product Sections */}
   <Box flex="1" overflowY="auto">
    {groupedData.map((item, index) => (
     <Box
      key={item.subCategoryName}
      ref={(el) => (sectionRefs.current[index] = el)}
      data-subcategory={item.subCategoryName}
      p={4}
      bg={activeTab === item.subCategoryName ? "gray.100" : "white"}
      borderBottom="1px solid #E0E0E0"
     >
      <Heading size="md" mb={4}>
       {item.subCategoryName}
      </Heading>

      <SimpleGrid columns={1} spacing={2}>
       {item.products.map((product) => (
        <Flex
         key={product._id}
         minH="160px"
         direction={{ base: "column", md: "row" }}
         borderRadius="md"
         borderWidth="1px"
         sx={{
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
         }}
         p={4}
         bg="#f9f9f9"
         align="stretch"
        >
         <Image
          src={
           product.productImage ||
           "https://m.media-amazon.com/images/I/51-6Y+fNmAL._SX522_.jpg"
          }
          alt={product.productName}
          boxSize={{ base: "100px", md: "80px" }}
          objectFit="cover"
          borderRadius="md"
          bg="white"
          mb={{ base: 4, md: 0 }}
         />

         <Box flex="1" px={4}>
          <Heading size="sm" fontWeight="bold" mb={2}>
           {product.productName || "Basic Car Service"}
          </Heading>

          <SimpleGrid columns={{ base: 2, md: 2, lg: 3 }} spacing={2} w="100%">
           {product.highlights?.map((highlight, i) => (
            <Flex key={i} alignItems="flex-start" mb={2}>
             {/* CheckCircleIcon */}
             <Icon as={CheckCircleIcon} color="red.500" mt={1} mr={2} />
             {/* Highlight Text */}
             <Text fontSize="sm" color="gray.700">
              {highlight.text || "Highlight"}
             </Text>
            </Flex>
           ))}

           {/* Expandable Section */}
           {expandedCard === product._id && (
            <>
             {product.additionalServices?.map((highlight, i) => (
              <Flex key={i} alignItems="flex-start" mb={2}>
               {/* Icon */}
               <Icon as={CheckCircleIcon} color="red.500" mt={1} mr={2} />
               {/* Highlight Text */}
               <Text fontSize="sm" color="gray.700">
                {highlight || "Additional Service"}
               </Text>
              </Flex>
             ))}

             {product.includedService?.map((serviceGroup) =>
              serviceGroup.services.map((service) => (
               <Flex key={service._id} alignItems="flex-start" mb={2}>
                {/* Icon */}
                <Icon as={CheckCircleIcon} color="red.500" mt={1} mr={2} />
                {/* Service Text */}
                <Text fontSize="sm" color="gray.700">
                 {service.title}
                </Text>
               </Flex>
              ))
             )}
            </>
           )}
          </SimpleGrid>

          <Button
           size="sm"
           mt={2}
           onClick={() => toggleShowMore(product._id)}
           colorScheme="blue"
           variant="link"
          >
           {expandedCard === product._id ? "Show Less" : "Show More"}
          </Button>
         </Box>

         <VStack
          align="stretch"
          spacing={2}
          mt={{ base: 4, md: 0 }}
          textAlign={{ base: "left", md: "right" }}
         >
          <Text fontSize="lg" fontWeight="bold" color="red.600">
           ₹{product.dummyPriceActual || "2497"}
          </Text>
          <Button
           colorScheme="red"
           size="sm"
           px={6}
           _hover={{ bg: "red.600" }}
           onClick={() => handleAddToCart(product, "Product")}
           isDisabled={isProductInCart(product?._id)}
          >
           {isProductInCart(product?._id) ? "Already in Cart" : "Add To Cart +"}
          </Button>
         </VStack>
        </Flex>
       ))}
      </SimpleGrid>
     </Box>
    ))}
   </Box>
  </Flex>
 );
};

export default ListingPage;

const data = [
 {
  _id: "677f9e7bb237ec487b659e2b",
  categoryId: {
   _id: "677ec493b237ec487b6596f4",
   tag: "Popular",
   variantType: "Car",
   categoryName: "Car Wash",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736361107413-10317150.png",
   createdAt: "2025-01-08T18:31:47.469Z",
   updatedAt: "2025-01-08T18:56:02.317Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ecc90b237ec487b659772",
   categoryId: "677ec493b237ec487b6596f4",
   subCategoryName: "Pressure Wash",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736363150981-Screenshot_2023-06-30-13-20-28-397-edit_com.gallery.player.jpg",
   createdAt: "2025-01-08T19:05:52.162Z",
   updatedAt: "2025-01-08T19:05:52.162Z",
   __v: 0,
  },
  productName: "pressure foam wash",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736418045139-der.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736415616140-images%20%281%29.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736415628047-M5.png",
  ],
  highlights: [
   {
    text: "High Pressure Wash",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736416550405-istockphoto-1290697762-170667a.jpg",
    _id: "677f9e7bb237ec487b659e2c",
   },
   {
    text: "20 MINS",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736416454854-images%20%281%29.png",
    _id: "677f9e7bb237ec487b659e2d",
   },
   {
    text: "Doorstep Services",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736416510022-images.png",
    _id: "677f9e7bb237ec487b659e2e",
   },
  ],
  tags: [
   {
    name: "Is Recommended",
    value: "Recommended",
    _id: "677f9e7bb237ec487b659e2f",
   },
   {
    name: "IS TIME",
    value: "20 Mins",
    _id: "677f9e7bb237ec487b659e30",
   },
   {
    name: "is Doorstep service",
    value: "DoorStep Service",
    _id: "677f9e7bb237ec487b659e31",
   },
  ],
  offerTag: [
   {
    name: "PRICE DROP",
    value: "10% Off On First Service",
    _id: "677f9e7bb237ec487b659e32",
   },
   {
    name: "Is Price Drop 2",
    value: "05% Additional Off With Membership",
    _id: "677f9e7bb237ec487b659e33",
   },
  ],
  includedService: [],
  isTrending: true,
  additionalServices: [
   "Interior Vaccuming",
   "Dashboard Polish",
   "Mats Cleaning",
   "Foam Wash",
   "Doorstep Service",
  ],
  stepsAfterBooking: [
   "Our Team Post Verification Team Call You After Booking",
   "Our Cleaner Be On Time On Given Slots And Date",
   "Hassel Free Service . Provide Electricity Connection From Your House ",
  ],
  ratings: "5",
  dummyPriceMrp: "299",
  dummyPriceActual: "199",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "677fb498b237ec487b65a2b1",
  categoryId: {
   _id: "677ec401b237ec487b6596e7",
   tag: "Recommended",
   variantType: "Car",
   categoryName: "Car Deep Cleaning",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736418532101-10317150.png",
   createdAt: "2025-01-08T18:29:21.434Z",
   updatedAt: "2025-01-09T10:28:52.203Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ecb01b237ec487b65975f",
   categoryId: "677ec401b237ec487b6596e7",
   subCategoryName: "Interior Deep Cleaning",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736362753329-IMG-20230630-WA0017.jpg",
   createdAt: "2025-01-08T18:59:13.456Z",
   updatedAt: "2025-01-08T18:59:13.456Z",
   __v: 0,
  },
  productName: "interior dry-cleaning + foam wash",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736421796161-iop.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736421808006-images%20%281%29.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736421815556-images.jpg",
  ],
  highlights: [
   {
    text: "Interior Dry Cleaning",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736422005299-1000_F_355273672_MPJa4N9TWFpKwtH0XrQIgiHtQ7XUl4Fx.jpg",
    _id: "677fb498b237ec487b65a2b2",
   },
   {
    text: "2 Hrs Taken",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736422025912-images%20%281%29.png",
    _id: "677fb498b237ec487b65a2b3",
   },
   {
    text: "Free Foam Wash",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736422106821-clean-car-foam-icon-outline-style-vector.jpg",
    _id: "677fb498b237ec487b65a2b4",
   },
  ],
  tags: [
   {
    name: "IS RECCOMED",
    value: "Recommended",
    _id: "677fb498b237ec487b65a2b5",
   },
   {
    name: "IS TIME",
    value: "90 Mins",
    _id: "677fb498b237ec487b65a2b6",
   },
  ],
  offerTag: [
   {
    name: "PRICE DROP",
    value: "10% Off  On 1st  Service",
    _id: "677fb498b237ec487b65a2b7",
   },
   {
    name: "Is Price Drop 2",
    value: "05% Additional off With Membership",
    _id: "677fb498b237ec487b65a2b8",
   },
  ],
  includedService: [
   {
    name: "",
    services: [
     {
      title: "",
      image: "",
      _id: "677fb498b237ec487b65a2ba",
     },
    ],
    _id: "677fb498b237ec487b65a2b9",
   },
  ],
  isTrending: true,
  additionalServices: [
   "All Seats Dry-cleaning ",
   "Roof Dry-cleaning",
   "Floor Dry-cleaning",
   "All Gates Panel Dry-cleaning",
   "Diggy Panel Dry-cleaning",
   "Dashboard Panel Dry-cleaning",
   "Dashboard Polish",
   "Interior Vacuuming",
   "Mats Cleaning",
  ],
  stepsAfterBooking: [
   "When Order Confirmed Dedicated Rm will Call You.",
   "Provide Electricity Connection From Your Home.",
   "Provide Proper Parking Space.",
   "Hassel Fee Service.",
  ],
  ratings: "4.9",
  dummyPriceMrp: "1199",
  dummyPriceActual: "799",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "677fb934b237ec487b65a3f8",
  categoryId: {
   _id: "677ec401b237ec487b6596e7",
   tag: "Recommended",
   variantType: "Car",
   categoryName: "Car Deep Cleaning",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736418532101-10317150.png",
   createdAt: "2025-01-08T18:29:21.434Z",
   updatedAt: "2025-01-09T10:28:52.203Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ecb01b237ec487b65975f",
   categoryId: "677ec401b237ec487b6596e7",
   subCategoryName: "Interior Deep Cleaning",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736362753329-IMG-20230630-WA0017.jpg",
   createdAt: "2025-01-08T18:59:13.456Z",
   updatedAt: "2025-01-08T18:59:13.456Z",
   __v: 0,
  },
  productName: "interior steam dry-cleaning",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736423966524-pli.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736422674608-images.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736422681278-images%20%281%29.jpg",
  ],
  highlights: [
   {
    text: "Steam Dry-Cleaning",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736423133993-192198544-car-wash-icon-auto-clean-service-and-care-vector-pictogram-of-soap-and-water-bubbles-car-hand-wash.jpg",
    _id: "677fb934b237ec487b65a3f9",
   },
   {
    text: "2 Hrs Taken",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736423153382-images%20%281%29.png",
    _id: "677fb934b237ec487b65a3fa",
   },
   {
    text: "Free Foam Wash",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736423328398-foam-wash-car-icon-flat-style-vector.jpg",
    _id: "677fb934b237ec487b65a3fb",
   },
   {
    text: "Complete Interior Cleaning",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736423372885-1000_F_355273672_MPJa4N9TWFpKwtH0XrQIgiHtQ7XUl4Fx.jpg",
    _id: "677fb934b237ec487b65a3fc",
   },
   {
    text: "",
    icon: "",
    _id: "677fbb2eb237ec487b65a5ed",
   },
  ],
  tags: [
   {
    name: "Is Trending",
    value: "Trending Now",
    _id: "677fb934b237ec487b65a3fd",
   },
   {
    name: "IS TIME",
    value: "120 Mins",
    _id: "677fb934b237ec487b65a3fe",
   },
   {
    name: "Is DoorStep Service",
    value: "Door Step Service",
    _id: "677fb934b237ec487b65a3ff",
   },
   {
    name: "",
    value: "",
    _id: "677fe2140167d1c72bb67df8",
   },
  ],
  offerTag: [
   {
    name: "PRICE DROP",
    value: "10% Additional Off with Certain Time Slots",
    _id: "677fb934b237ec487b65a400",
   },
   {
    name: "Is Price Drop 2",
    value: "10% Additional Off With Membership",
    _id: "677fb934b237ec487b65a401",
   },
  ],
  includedService: [
   {
    name: "",
    services: [
     {
      title: "",
      image: "",
      _id: "677fb934b237ec487b65a403",
     },
    ],
    _id: "677fb934b237ec487b65a402",
   },
  ],
  isTrending: false,
  additionalServices: [
   "All Seat Steam Dry-Cleaning",
   "Roof Steam Dry-Cleaning",
   "Floor Steam Dry-Cleaning",
   "Gates Panel Steam Dry-Cleaning",
   "Diggy Panel Steam Dry-Cleaning",
   " Dashboard Panel Steam Dry-Cleaning",
   "Complete Foam Wash",
   " Dashboard Polishing",
   "Mats Cleaning",
  ],
  stepsAfterBooking: [
   "When Order Confirmed Dedicated Rm Will Call You.",
   "Provide Electricity Connection From Your Home.",
   "Provide Proper Parking Space.",
   "Hassel Fee Service.",
  ],
  ratings: "5",
  dummyPriceMrp: "1599",
  dummyPriceActual: "1099",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "677fc386b237ec487b65a7a4",
  categoryId: {
   _id: "677ec401b237ec487b6596e7",
   tag: "Recommended",
   variantType: "Car",
   categoryName: "Car Deep Cleaning",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736418532101-10317150.png",
   createdAt: "2025-01-08T18:29:21.434Z",
   updatedAt: "2025-01-09T10:28:52.203Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ecb01b237ec487b65975f",
   categoryId: "677ec401b237ec487b6596e7",
   subCategoryName: "Interior Deep Cleaning",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736362753329-IMG-20230630-WA0017.jpg",
   createdAt: "2025-01-08T18:59:13.456Z",
   updatedAt: "2025-01-08T18:59:13.456Z",
   __v: 0,
  },
  productName: "combo pack of interior & exterior both",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736425726460-combo%20pack%20pic.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736425740360-images.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736425748970-images%20%281%29.jpg",
  ],
  highlights: [
   {
    text: "Rubbing Polish & Dry-cleaning ",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736425884560-polish-car-wash-service-color-icon-vector-illustration-2G5J34N.jpg",
    _id: "677fc386b237ec487b65a7a5",
   },
   {
    text: "3 Hrs Taken",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736425905905-images%20%281%29.png",
    _id: "677fc386b237ec487b65a7a6",
   },
   {
    text: "Heavy Discount ",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736425977910-big-discount-rubber-grunge-stamp-seal-stock-vector.jpg",
    _id: "677fc386b237ec487b65a7a7",
   },
  ],
  tags: [
   {
    name: "IS Discount",
    value: "Heavy Discount ",
    _id: "677fc386b237ec487b65a7a8",
   },
   {
    name: "IS TIME",
    value: "180 Mins",
    _id: "677fc386b237ec487b65a7a9",
   },
   {
    name: "Is Doorstep Service",
    value: "Doorstep Service",
    _id: "677fc386b237ec487b65a7aa",
   },
  ],
  offerTag: [
   {
    name: "PRICE DROP",
    value: "10% Additional Off With Certain Time Slots",
    _id: "677fc386b237ec487b65a7ab",
   },
   {
    name: "Is Price Drop 2",
    value: "10% Additional Off With Membership",
    _id: "677fc386b237ec487b65a7ac",
   },
  ],
  includedService: [
   {
    name: "",
    services: [
     {
      title: "",
      image: "",
      _id: "677fc386b237ec487b65a7ae",
     },
    ],
    _id: "677fc386b237ec487b65a7ad",
   },
  ],
  isTrending: false,
  additionalServices: [
   "Rubbing With Machine",
   "Complete Dry-cleaning ",
   "Complete Interior Or Exterior Polish ",
   "Engine Cleaning",
   "Tyre  Polishing",
   "Complete Pressure Foam Wash",
   "Seats, Roof, Floor Drycleaning",
   "Gates Panel And Diggy Panel Dry-cleaning",
  ],
  stepsAfterBooking: [
   "When Order Confirmed Dedicated Rm Will Call You.",
   "Provide Electricity Connection From Your Home.",
   "Provide Proper Parking Space.",
   "Rubbing With Machine & Polish Apply With Applicators.",
  ],
  ratings: "5",
  dummyPriceMrp: "2599",
  dummyPriceActual: "1399",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "677fc665b237ec487b65a86d",
  categoryId: {
   _id: "677ec401b237ec487b6596e7",
   tag: "Recommended",
   variantType: "Car",
   categoryName: "Car Deep Cleaning",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736418532101-10317150.png",
   createdAt: "2025-01-08T18:29:21.434Z",
   updatedAt: "2025-01-09T10:28:52.203Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ecb88b237ec487b659764",
   categoryId: "677ec401b237ec487b6596e7",
   subCategoryName: "Rubbing & Polishing",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736362888402-IMG-20230630-WA0024.jpg",
   createdAt: "2025-01-08T19:01:28.626Z",
   updatedAt: "2025-01-08T19:01:28.626Z",
   __v: 0,
  },
  productName: "rubbing & polishing",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736426590268-rubbing%20pic.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736426598693-images.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736426605482-images%20%281%29.jpg",
  ],
  highlights: [
   {
    text: "Complete Rubbing With Machine",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736426927989-car-rubbing-machine-icon-design-48x48-pixel-vector-32180178.jpg",
    _id: "677fc665b237ec487b65a86e",
   },
   {
    text: "2 Hrs Taken",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736426949339-images%20%281%29.png",
    _id: "677fc665b237ec487b65a86f",
   },
   {
    text: "Complete Foam Wash",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736426980187-foam-wash-car-icon-flat-style-vector.jpg",
    _id: "677fc665b237ec487b65a870",
   },
   {
    text: "Doorstep Service",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736426992542-images.png",
    _id: "677fc665b237ec487b65a871",
   },
  ],
  tags: [
   {
    name: "IS Popular",
    value: "Most Popular",
    _id: "677fc665b237ec487b65a872",
   },
   {
    name: "IS TIME",
    value: "90 Mins",
    _id: "677fc665b237ec487b65a873",
   },
   {
    name: "Is Doorstep Service",
    value: "Doorstep Service",
    _id: "677fc665b237ec487b65a874",
   },
  ],
  offerTag: [
   {
    name: "PRICE DROP",
    value: "10% Additional Off With Certain Time Slots",
    _id: "677fc665b237ec487b65a875",
   },
   {
    name: "Is Price Drop 2",
    value: "10% Additional Off With Membership.",
    _id: "677fc665b237ec487b65a876",
   },
  ],
  includedService: [
   {
    name: "",
    services: [
     {
      title: "",
      image: "",
      _id: "677fc665b237ec487b65a878",
     },
    ],
    _id: "677fc665b237ec487b65a877",
   },
  ],
  isTrending: true,
  additionalServices: [
   "Complete Rubbing With Machine",
   "Complete Foam Wash ",
   "Complete Exterior Polish ",
   "Engine Cleaning",
   "Tyre Cleaning",
   "Tyre Polishing",
   "All Branded items Used",
  ],
  stepsAfterBooking: [
   "When Order Confirmed Dedicated Rm will Call You.",
   "Provide Electricity Connection From Your Home.",
   "Provide Proper Parking Space.",
   "Rubbing With Machine & Polish Apply With Applicators.",
   "Hassel Fee Service.",
  ],
  ratings: "5",
  dummyPriceMrp: "1599",
  dummyPriceActual: "899",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "677fca7cb237ec487b65a9e0",
  categoryId: {
   _id: "677ec401b237ec487b6596e7",
   tag: "Recommended",
   variantType: "Car",
   categoryName: "Car Deep Cleaning",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736418532101-10317150.png",
   createdAt: "2025-01-08T18:29:21.434Z",
   updatedAt: "2025-01-09T10:28:52.203Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ecb88b237ec487b659764",
   categoryId: "677ec401b237ec487b6596e7",
   subCategoryName: "Rubbing & Polishing",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736362888402-IMG-20230630-WA0024.jpg",
   createdAt: "2025-01-08T19:01:28.626Z",
   updatedAt: "2025-01-08T19:01:28.626Z",
   __v: 0,
  },
  productName: "combo pack of interior and exterior both",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736427786979-combo%20pack%20pic.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736427796233-images.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736427802436-images%20%281%29.jpg",
  ],
  highlights: [
   {
    text: "Rubbing Polish & Dry-cleaning ",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736427864787-polish-car-wash-service-color-icon-vector-illustration-2G5J34N.jpg",
    _id: "677fca7cb237ec487b65a9e1",
   },
   {
    text: "3 Hrs Taken",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736427824451-images%20%281%29.png",
    _id: "677fca7cb237ec487b65a9e2",
   },
   {
    text: "DoorStep Service",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736427833812-images.png",
    _id: "677fca7cb237ec487b65a9e3",
   },
   {
    text: "Heavy Discount",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736427897566-big-discount-rubber-grunge-stamp-seal-stock-vector.jpg",
    _id: "677fca7cb237ec487b65a9e4",
   },
  ],
  tags: [
   {
    name: "Is Discount ",
    value: "Heavy Discount ",
    _id: "677fca7cb237ec487b65a9e5",
   },
   {
    name: "IS TIME",
    value: "180 Mins",
    _id: "677fca7cb237ec487b65a9e6",
   },
   {
    name: "Is DoorStep Service",
    value: "DoorStep Service",
    _id: "677fca7cb237ec487b65a9e7",
   },
  ],
  offerTag: [
   {
    name: "PRICE DROP",
    value: "10% Additional Off With Certain Time Slots",
    _id: "677fca7cb237ec487b65a9e8",
   },
   {
    name: "Is Price Drop 2",
    value: "10% Additional Off With Membership",
    _id: "677fca7cb237ec487b65a9e9",
   },
  ],
  includedService: [
   {
    name: "",
    services: [
     {
      title: "",
      image: "",
      _id: "677fca7cb237ec487b65a9eb",
     },
    ],
    _id: "677fca7cb237ec487b65a9ea",
   },
  ],
  isTrending: true,
  additionalServices: [
   "Rubbing With Machine",
   "Complete Dry-cleaning ",
   "Complete Interior Or Exterior Polish ",
   "Engine Cleaning",
   "Tyre  Polishing",
   "Complete Pressure Foam Wash",
   "Seats, Roof, Floor Drycleaning",
   "Gates Panel And Diggy Panel Dry-cleaning",
  ],
  stepsAfterBooking: [
   "When Order Confirmed Dedicated Rm will Call You.",
   "Provide Electricity Connection From Your Home.",
   "Provide Proper Parking Space.",
   "Rubbing With Machine & Polish Apply With Applicators.",
   "Hassel Fee Service.",
  ],
  ratings: "5",
  dummyPriceMrp: "2599",
  dummyPriceActual: "1399",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "677fe5300167d1c72bb67edf",
  categoryId: {
   _id: "677ec493b237ec487b6596f4",
   tag: "Popular",
   variantType: "Car",
   categoryName: "Car Wash",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736361107413-10317150.png",
   createdAt: "2025-01-08T18:31:47.469Z",
   updatedAt: "2025-01-08T18:56:02.317Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ecc90b237ec487b659772",
   categoryId: "677ec493b237ec487b6596f4",
   subCategoryName: "Pressure Wash",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736363150981-Screenshot_2023-06-30-13-20-28-397-edit_com.gallery.player.jpg",
   createdAt: "2025-01-08T19:05:52.162Z",
   updatedAt: "2025-01-08T19:05:52.162Z",
   __v: 0,
  },
  productName: "foam wash + wax + underbody cleaning",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736434548664-foam%20wash%20%26%20wax.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736434558748-images%20%281%29.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736434566205-images.jpg",
  ],
  highlights: [
   {
    text: "High Pressure Wash",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736434632535-istockphoto-1290697762-170667a.jpg",
    _id: "677fe5300167d1c72bb67ee0",
   },
   {
    text: "Complete Body Wax",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736434622641-istockphoto-1273682054-612x612.jpg",
    _id: "677fe5300167d1c72bb67ee1",
   },
   {
    text: "UnderBody Cleaning",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736434736157-underbody-wash_icon.png",
    _id: "677fe5300167d1c72bb67ee2",
   },
  ],
  tags: [
   {
    name: "Is Most Selling",
    value: "Most Selling",
    _id: "677fe5300167d1c72bb67ee3",
   },
   {
    name: "IS TIME",
    value: "30 Mins",
    _id: "677fe5300167d1c72bb67ee4",
   },
   {
    name: "Is DoorStep Service",
    value: "DoorStep Service",
    _id: "677fe5300167d1c72bb67ee5",
   },
  ],
  offerTag: [
   {
    name: "PRICE DROP",
    value: "10% Additional off With Certain Time Slots .",
    _id: "677fe5300167d1c72bb67ee6",
   },
   {
    name: "Is Price Drop 2",
    value: "10% Additional Off With Membership .",
    _id: "677fe5300167d1c72bb67ee7",
   },
  ],
  includedService: [
   {
    name: "",
    services: [
     {
      title: "",
      image: "",
      _id: "677fe5300167d1c72bb67ee9",
     },
    ],
    _id: "677fe5300167d1c72bb67ee8",
   },
  ],
  isTrending: true,
  additionalServices: [
   "Interior Vaccuming",
   "Dashboard Polish",
   "Mats Cleaning",
   "Complete Body Wax",
   "Under Body Cleaning",
   "Foam Wash",
  ],
  stepsAfterBooking: [
   "Our Team Post Verification Team Call You After Booking.",
   "Our Cleaner Be On Time On Given Slots And Date.",
   "Hassel Free Service . Provide Electricity Connection From Your House ",
  ],
  ratings: "5",
  dummyPriceMrp: "499",
  dummyPriceActual: "299",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "677fe9210167d1c72bb68241",
  categoryId: {
   _id: "677ec493b237ec487b6596f4",
   tag: "Popular",
   variantType: "Car",
   categoryName: "Car Wash",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736361107413-10317150.png",
   createdAt: "2025-01-08T18:31:47.469Z",
   updatedAt: "2025-01-08T18:56:02.317Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ed107b237ec487b65977d",
   categoryId: "677ec493b237ec487b6596f4",
   subCategoryName: "Steam Wash ",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736364295715-IMG-20250106-WA0009.jpg",
   createdAt: "2025-01-08T19:24:55.852Z",
   updatedAt: "2025-01-08T19:24:55.852Z",
   __v: 0,
  },
  productName: "exterior steam wash",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736435651839-steam%20car%20wash.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736435660102-images.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736435667560-images%20%281%29.jpg",
  ],
  highlights: [
   {
    text: "Steam Car Wash",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736435990811-istockphoto-1320133360-612x612.jpg",
    _id: "677fe9210167d1c72bb68242",
   },
   {
    text: "1 Hrs Taken",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736435944260-images%20%281%29.png",
    _id: "677fe9210167d1c72bb68243",
   },
   {
    text: "Door Step Service",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736435949477-images.png",
    _id: "677fe9210167d1c72bb68244",
   },
  ],
  tags: [
   {
    name: "IS Customer Choice",
    value: "Customer Choice",
    _id: "677fe9210167d1c72bb68245",
   },
   {
    name: "IS TIME",
    value: "35 Mins",
    _id: "677fe9210167d1c72bb68246",
   },
   {
    name: "Is DoorStep Service",
    value: "Door Step Service",
    _id: "677fe9210167d1c72bb68247",
   },
  ],
  offerTag: [
   {
    name: "PRICE DROP",
    value: "10% Additional off With Certain Time Slots .",
    _id: "677fe9210167d1c72bb68248",
   },
   {
    name: "Is Price Drop 2",
    value: "10% Additional Off With Membership .",
    _id: "677fe9210167d1c72bb68249",
   },
  ],
  includedService: [
   {
    name: "",
    services: [
     {
      title: "",
      image: "",
      _id: "677fe9210167d1c72bb6824b",
     },
    ],
    _id: "677fe9210167d1c72bb6824a",
   },
  ],
  isTrending: false,
  additionalServices: [
   "Complete Exterior Steam Wash ",
   "Internal Vacuum",
   "DashBoard Polish",
   "Internal Cleaning",
   "Mats Cleaning",
  ],
  stepsAfterBooking: [
   "When Order Confirmed Dedicated Rm Will Call You.",
   "Provide Electricity Connection From Your Home.",
   "Provide Proper Parking Space.",
   "Hassel Fee Service.",
  ],
  ratings: "5",
  dummyPriceMrp: "799",
  dummyPriceActual: "499",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "677feba10167d1c72bb683bb",
  categoryId: {
   _id: "677ec493b237ec487b6596f4",
   tag: "Popular",
   variantType: "Car",
   categoryName: "Car Wash",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736361107413-10317150.png",
   createdAt: "2025-01-08T18:31:47.469Z",
   updatedAt: "2025-01-08T18:56:02.317Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ed107b237ec487b65977d",
   categoryId: "677ec493b237ec487b6596f4",
   subCategoryName: "Steam Wash ",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736364295715-IMG-20250106-WA0009.jpg",
   createdAt: "2025-01-08T19:24:55.852Z",
   updatedAt: "2025-01-08T19:24:55.852Z",
   __v: 0,
  },
  productName: "interior & exterior steam cleaning",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736436349136-interior%20steam.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736436359187-images.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736436366570-images%20%281%29.jpg",
  ],
  highlights: [
   {
    text: "Exterior Steam Wash",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736436463434-istockphoto-1320133360-612x612.jpg",
    _id: "677feba10167d1c72bb683bc",
   },
   {
    text: "Interior Steam Wash",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736436471008-interior%20steam.png",
    _id: "677feba10167d1c72bb683bd",
   },
   {
    text: "1 Hrs Taken",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736436451215-images%20%281%29.png",
    _id: "677feba10167d1c72bb683be",
   },
  ],
  tags: [
   {
    name: "Is Demand",
    value: "High Demand",
    _id: "677feba10167d1c72bb683bf",
   },
   {
    name: "IS TIME",
    value: "60 Mins",
    _id: "677feba10167d1c72bb683c0",
   },
   {
    name: "Is DoorStep Service",
    value: "DoorStep Service",
    _id: "677feba10167d1c72bb683c1",
   },
  ],
  offerTag: [
   {
    name: "PRICE DROP",
    value: "10% Additional off With Certain Time Slots .",
    _id: "677feba10167d1c72bb683c2",
   },
   {
    name: "Is Price Drop 2",
    value: "10% Additional Off With Membership .",
    _id: "677feba10167d1c72bb683c3",
   },
  ],
  includedService: [
   {
    name: "",
    services: [
     {
      title: "",
      image: "",
      _id: "677feba10167d1c72bb683c5",
     },
    ],
    _id: "677feba10167d1c72bb683c4",
   },
  ],
  isTrending: false,
  additionalServices: [
   "Complete Exterior Steam Wash ",
   "Complete Interior Steam Wash ",
   "Mats Cleaning",
   "Internal Cleaning",
   " Internal Vacuuming ",
   "Dashboard Polish",
  ],
  stepsAfterBooking: [
   "When Order Confirmed Dedicated Rm Will Call You.",
   "Provide Electricity Connection From Your Home.",
   "Provide Proper Parking Space.",
   "Hassel Fee Service.",
  ],
  ratings: "5",
  dummyPriceMrp: "999",
  dummyPriceActual: "799",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "677fed7c0167d1c72bb6847f",
  categoryId: {
   _id: "677ec493b237ec487b6596f4",
   tag: "Popular",
   variantType: "Car",
   categoryName: "Car Wash",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736361107413-10317150.png",
   createdAt: "2025-01-08T18:31:47.469Z",
   updatedAt: "2025-01-08T18:56:02.317Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ed12bb237ec487b659782",
   categoryId: "677ec493b237ec487b6596f4",
   subCategoryName: "Diesel Wash ",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736364331604-IMG-20250106-WA0010.jpg",
   createdAt: "2025-01-08T19:25:31.781Z",
   updatedAt: "2025-01-08T19:25:31.781Z",
   __v: 0,
  },
  productName: "exterior diesel wash",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736436795703-disel%20wash.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736436806006-images.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736436812569-images%20%281%29.jpg",
  ],
  highlights: [
   {
    text: "Exterior Diesel Wash ",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736437053989-10-1378798217-car-wash-tips-08.jpg",
    _id: "677fed7c0167d1c72bb68480",
   },
   {
    text: "Engine Cleaning",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736437111696-357-3571289_engine-room-fine-wash-car-engine-wash-icon.png",
    _id: "677fed7c0167d1c72bb68481",
   },
   {
    text: "Internal Cleaning Covered",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736437075832-Car_Seat_Cleaning-512.webp",
    _id: "677fed7c0167d1c72bb68482",
   },
  ],
  tags: [
   {
    name: "Is See Side",
    value: "Sea Side",
    _id: "677fed7c0167d1c72bb68483",
   },
   {
    name: "IS TIME",
    value: "30 Mins",
    _id: "677fed7c0167d1c72bb68484",
   },
   {
    name: "Is DoorStep Service",
    value: "Door Step Service",
    _id: "677fed7c0167d1c72bb68485",
   },
  ],
  offerTag: [
   {
    name: "PRICE DROP",
    value: "10% Additional off With Certain Time Slots .",
    _id: "677fed7c0167d1c72bb68486",
   },
   {
    name: "Is Price Drop 2",
    value: "10% Additional Off With Membership .",
    _id: "677fed7c0167d1c72bb68487",
   },
  ],
  includedService: [
   {
    name: "",
    services: [
     {
      title: "",
      image: "",
      _id: "677fed7c0167d1c72bb68489",
     },
    ],
    _id: "677fed7c0167d1c72bb68488",
   },
  ],
  isTrending: false,
  additionalServices: [
   "Complete Exterior Diesel Wash ",
   "Internal Vacuum",
   "Mats Cleaning",
   "Internal Cleaning",
   "Dash Board Polishing ",
   "Engine Cleaning",
  ],
  stepsAfterBooking: [
   "When Order Confirmed Dedicated Rm Will Call You.",
   "Provide Electricity Connection From Your Home.",
   "Provide Proper Parking Space.",
   "Hassel Fee Service.",
  ],
  ratings: "5",
  dummyPriceMrp: "899",
  dummyPriceActual: "499",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "6780133aaf8e3b24b4fa039d",
  categoryId: {
   _id: "677ec58eb237ec487b659705",
   tag: "Heavy Discount",
   variantType: "Car",
   categoryName: "Car Detailing",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736361358470-icon.png",
   createdAt: "2025-01-08T18:35:58.626Z",
   updatedAt: "2025-01-08T18:35:58.626Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ed5adb237ec487b6597bd",
   categoryId: "677ec58eb237ec487b659705",
   subCategoryName: "Teflon Coating",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736365485290-images.jpg",
   createdAt: "2025-01-08T19:44:45.398Z",
   updatedAt: "2025-01-08T19:44:45.398Z",
   __v: 0,
  },
  productName: "exterior teflon coating",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736446138513-teflon%20coat.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736446160284-images%20%281%29.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736446168927-images.jpg",
  ],
  highlights: [
   {
    text: "Remove Minor Scratches",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736446401552-polish-car-wash-service-color-icon-vector-illustration-2G5J34N.jpg",
    _id: "6780133aaf8e3b24b4fa039e",
   },
   {
    text: "3 Hrs Taken",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736446228730-images%20%281%29.png",
    _id: "6780133aaf8e3b24b4fa039f",
   },
   {
    text: "Every Year Recommended",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736446294905-reccomend.jpg",
    _id: "6780133aaf8e3b24b4fa03a0",
   },
  ],
  tags: [
   {
    name: "IS Free",
    value: "Free Dry-Clean",
    _id: "6780133aaf8e3b24b4fa03a1",
   },
   {
    name: "IS TIME",
    value: "200 Mins",
    _id: "6780133aaf8e3b24b4fa03a2",
   },
   {
    name: "Is DoorStep Service",
    value: "Door Step Service",
    _id: "6780133aaf8e3b24b4fa03a3",
   },
  ],
  offerTag: [
   {
    name: "PRICE DROP",
    value: "10% Additional off With Certain Time Slots .",
    _id: "6780133aaf8e3b24b4fa03a4",
   },
   {
    name: "Is Price Drop 2",
    value: "10% Additional Off With Membership .",
    _id: "6780133aaf8e3b24b4fa03a5",
   },
  ],
  includedService: [
   {
    name: "Value Added Services",
    services: [
     {
      title: "Full Body Teflon Coating",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736446469751-teflon%20coat.png",
      _id: "6780133aaf8e3b24b4fa03a7",
     },
     {
      title: " Minor Scratches Remove",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736446525860-teflon.jpg",
      _id: "6780133aaf8e3b24b4fa03a8",
     },
    ],
    _id: "6780133aaf8e3b24b4fa03a6",
   },
  ],
  isTrending: false,
  additionalServices: [
   "Sanding With Sand Paper",
   "Complete Exterior Rubbing ",
   "Ultra Shine Polishing",
   "Exterior Pressure Foam Wash ",
   "Interior Cleaning",
  ],
  stepsAfterBooking: [
   "When Order Confirmed Dedicated Rm Will Call You.",
   "Provide Electricity Connection From Your Home.",
   "Provide Proper Parking Space.",
   "Hassel Fee Service.",
  ],
  ratings: "5",
  dummyPriceMrp: "2999",
  dummyPriceActual: "2499",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "67801884af8e3b24b4fa0619",
  categoryId: {
   _id: "677ec58eb237ec487b659705",
   tag: "Heavy Discount",
   variantType: "Car",
   categoryName: "Car Detailing",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736361358470-icon.png",
   createdAt: "2025-01-08T18:35:58.626Z",
   updatedAt: "2025-01-08T18:35:58.626Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ed426b237ec487b6597a8",
   categoryId: "677ec58eb237ec487b659705",
   subCategoryName: "Ceramic Coating",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736365131788-IMG-20250106-WA0005.jpg",
   createdAt: "2025-01-08T19:38:14.909Z",
   updatedAt: "2025-01-08T19:38:51.924Z",
   __v: 0,
  },
  productName: "9h  ceramic coating",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736447275858-ceramic%20coat.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736447330931-images.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736447339188-images%20%281%29.jpg",
  ],
  highlights: [
   {
    text: "1/3/5 Year Warranty",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736447513540-warranty.jpg",
    _id: "67801884af8e3b24b4fa061a",
   },
   {
    text: "8 Hrs Taken",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736447434956-images%20%281%29.png",
    _id: "67801884af8e3b24b4fa061b",
   },
   {
    text: "9H Ceramic Coating",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736447442508-istockphoto-1273682054-612x612.jpg",
    _id: "67801884af8e3b24b4fa061c",
   },
  ],
  tags: [
   {
    name: "Is Free Detailing",
    value: "Free Detailing",
    _id: "67801884af8e3b24b4fa061d",
   },
   {
    name: "IS TIME",
    value: "480 Mins",
    _id: "67801884af8e3b24b4fa061e",
   },
   {
    name: "Is Pick and Drop",
    value: "Pick and Drop Service",
    _id: "67801884af8e3b24b4fa061f",
   },
  ],
  offerTag: [
   {
    name: "PRICE DROP",
    value: "10% Additional off With Certain Time Slots .",
    _id: "67801884af8e3b24b4fa0620",
   },
   {
    name: "Is Price Drop 2",
    value: "10% Additional Off With Membership .",
    _id: "67801884af8e3b24b4fa0621",
   },
  ],
  includedService: [
   {
    name: "Value Added Services",
    services: [
     {
      title: "UV Ray Protection",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736447843963-images.jpg",
      _id: "67801884af8e3b24b4fa0623",
     },
     {
      title: "Complete Interior Detailing",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736447832814-iop.png",
      _id: "67801884af8e3b24b4fa0624",
     },
     {
      title: "Advanced Nano Ceramic Technology",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736447941683-rtu.jpg",
      _id: "67801884af8e3b24b4fa0625",
     },
    ],
    _id: "67801884af8e3b24b4fa0622",
   },
  ],
  isTrending: false,
  additionalServices: [
   "Protection Against UV Rays & Color Fading",
   "Free Annual Maintaine",
   "3 Year Warranty",
   "Complete Car Detailing",
   "Free Car Perfume & Car Dustbin",
  ],
  stepsAfterBooking: [
   "When Order Confirmed Dedicated Rm Will Call You.",
   "Provide Electricity Connection From Your Home.",
   "Provide Proper Parking Space With Dust Free Area.",
   "Hassel Fee Service.",
  ],
  ratings: "5",
  dummyPriceMrp: "17999",
  dummyPriceActual: "14999",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "67810449af8e3b24b4fa351f",
  categoryId: {
   _id: "677ec52cb237ec487b659701",
   tag: "Free Inspection",
   variantType: "Car",
   categoryName: "Car Service",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736361260753-10317150.png",
   createdAt: "2025-01-08T18:34:20.865Z",
   updatedAt: "2025-01-08T18:34:20.865Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ed3d9b237ec487b65979e",
   categoryId: "677ec52cb237ec487b659701",
   subCategoryName: "Periodic Service",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736365017539-IMG-20250106-WA0007.jpg",
   createdAt: "2025-01-08T19:36:57.654Z",
   updatedAt: "2025-01-08T19:36:57.654Z",
   __v: 0,
  },
  productName: "standard service",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736507908169-car%20service%20image.png",
  productBannerImages: [""],
  highlights: [
   {
    text: "Complete General Service",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736507981255-istockphoto-1036660952-612x612.jpg",
    _id: "67810449af8e3b24b4fa3520",
   },
   {
    text: "3 Hrs Taken",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736508554650-images%20%281%29.png",
    _id: "67810449af8e3b24b4fa3521",
   },
   {
    text: "Every 10000 Kms Recommended",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736508604544-reccomend.jpg",
    _id: "67810449af8e3b24b4fa3522",
   },
   {
    text: "1000 Kms Warranty",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736507974531-wrn.jpg",
    _id: "67810449af8e3b24b4fa3523",
   },
  ],
  tags: [
   {
    name: "is recommemed",
    value: "Recommemed",
    _id: "67810449af8e3b24b4fa3524",
   },
   {
    name: "Is Time",
    value: "180 Mins",
    _id: "67810449af8e3b24b4fa3525",
   },
   {
    name: "Is Door Step Service",
    value: "Door Step Service",
    _id: "67810449af8e3b24b4fa3526",
   },
  ],
  offerTag: [
   {
    name: " Is Price Drop 1:",
    value: "10% Additional Off With Certain Time Slots.",
    _id: "67810449af8e3b24b4fa3527",
   },
   {
    name: " Is Price Drop 2:",
    value: "5% Additional Off Full Payments",
    _id: "67810449af8e3b24b4fa3528",
   },
  ],
  includedService: [
   {
    name: "Essential Service",
    services: [
     {
      title: "Engine Oil Replacement (Synthetic Oil)",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736508079144-engine%20oil.jpg",
      _id: "67810449af8e3b24b4fa352a",
     },
     {
      title: "Oil Filter Change",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736508088679-oil%20filter.jpg",
      _id: "67810449af8e3b24b4fa352b",
     },
     {
      title: "Air Filter Change",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736508097520-air%20filter.jpg",
      _id: "67810449af8e3b24b4fa352c",
     },
     {
      title: "Front Break Pad Cleaning",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736508115001-break%20pad.jpg",
      _id: "67810449af8e3b24b4fa352d",
     },
    ],
    _id: "67810449af8e3b24b4fa3529",
   },
   {
    name: "Performance Service",
    services: [
     {
      title: "Coolant Top Up ( 200 ml )",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736508146353-coolant.jpg",
      _id: "67810449af8e3b24b4fa352f",
     },
     {
      title: "Break Fuild Top Up",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736508169883-break%20fuild.jpg",
      _id: "67810449af8e3b24b4fa3530",
     },
     {
      title: "Ac Filter Cleaning",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736508192273-ac%20fiter.jpg",
      _id: "67810449af8e3b24b4fa3531",
     },
     {
      title: "Spark Plug Cleaning",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736508263199-spark.jpg",
      _id: "67810449af8e3b24b4fa3532",
     },
    ],
    _id: "67810449af8e3b24b4fa352e",
   },
  ],
  isTrending: true,
  additionalServices: [
   "Wiper Fluid Top Up",
   "Complete Washing",
   "Internal Cleaning",
   "Dashboard Polish",
   "Tyre Cleaning",
  ],
  stepsAfterBooking: [
   "When Order Confirmed Dedicated Rm Will Assign.",
   "Provide Proper Parking Space.",
   "Provide Electricity Connection From Your Home.",
   "Hassel Fee Service.",
  ],
  ratings: "5",
  dummyPriceMrp: "4799",
  dummyPriceActual: "3699",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "67811d19af8e3b24b4fa3c53",
  categoryId: {
   _id: "677ec52cb237ec487b659701",
   tag: "Free Inspection",
   variantType: "Car",
   categoryName: "Car Service",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736361260753-10317150.png",
   createdAt: "2025-01-08T18:34:20.865Z",
   updatedAt: "2025-01-08T18:34:20.865Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ed3d9b237ec487b65979e",
   categoryId: "677ec52cb237ec487b659701",
   subCategoryName: "Periodic Service",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736365017539-IMG-20250106-WA0007.jpg",
   createdAt: "2025-01-08T19:36:57.654Z",
   updatedAt: "2025-01-08T19:36:57.654Z",
   __v: 0,
  },
  productName: "major service",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514204185-major%20service.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514217356-images%20%281%29.jpg",
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514226418-images.jpg",
  ],
  highlights: [
   {
    text: "Complete Major Car Service",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514323631-istockphoto-1036660952-612x612.jpg",
    _id: "67811d19af8e3b24b4fa3c54",
   },
   {
    text: "4 Hrs Taken",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514337710-images%20%281%29.png",
    _id: "67811d19af8e3b24b4fa3c55",
   },
   {
    text: "Every 20000 Kms Recommended",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514355585-reccomend.jpg",
    _id: "67811d19af8e3b24b4fa3c56",
   },
   {
    text: "1000 Kms Warranty",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514423977-wrn.jpg",
    _id: "67811d19af8e3b24b4fa3c57",
   },
  ],
  tags: [
   {
    name: "is recommemed",
    value: "Most Selling",
    _id: "67811d19af8e3b24b4fa3c58",
   },
   {
    name: "Is Time",
    value: "240 Mins",
    _id: "67811d19af8e3b24b4fa3c59",
   },
   {
    name: "Is Door Step Service",
    value: "Doorstep Service",
    _id: "67811d19af8e3b24b4fa3c5a",
   },
  ],
  offerTag: [
   {
    name: " Is Price Drop 1:",
    value: "10% Additional Off With Certain Time Slots.",
    _id: "67811d19af8e3b24b4fa3c5b",
   },
   {
    name: " Is Price Drop 2:",
    value: "10% Additional Off With Membership.",
    _id: "67811d19af8e3b24b4fa3c5c",
   },
  ],
  includedService: [
   {
    name: "Essential Service",
    services: [
     {
      title: "Engine Oil Replacement (Synthetic Oil)",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514437142-engine%20oil.jpg",
      _id: "67811d19af8e3b24b4fa3c5e",
     },
     {
      title: "Oil Filter Change",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514445770-oil%20filter.jpg",
      _id: "67811d19af8e3b24b4fa3c5f",
     },
     {
      title: "Air Filter Change",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514455642-air%20filter.jpg",
      _id: "67811d19af8e3b24b4fa3c60",
     },
     {
      title: "Ac Filter Change",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514464649-ac%20fiter.jpg",
      _id: "67811d19af8e3b24b4fa3c61",
     },
    ],
    _id: "67811d19af8e3b24b4fa3c5d",
   },
   {
    name: "Performance Service",
    services: [
     {
      title: "Coolant Replace",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514489583-coolant.jpg",
      _id: "67811d19af8e3b24b4fa3c63",
     },
     {
      title: "Break Fluid Replace",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514509160-break%20fuild.jpg",
      _id: "67811d19af8e3b24b4fa3c64",
     },
     {
      title: "Suspension Checkup",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514532581-suspension.png",
      _id: "67811d19af8e3b24b4fa3c65",
     },
     {
      title: "Gear Oil Top Up",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736514547950-gear%20oil.jpg",
      _id: "67811d19af8e3b24b4fa3c66",
     },
    ],
    _id: "67811d19af8e3b24b4fa3c62",
   },
  ],
  isTrending: false,
  additionalServices: [
   "Rear Break Pad Cleaning",
   "Wiper Fluid Replace",
   "Car Scanning",
   "Interior Cleaning",
   "Battery Water Top Up",
   "Car Washing",
   "Dashboard Polishing",
   "Complete Car Care",
  ],
  stepsAfterBooking: [
   "When Order confirmed Dedicated Rm will Assign",
   "Provide Proper Parking Space.",
   "Provide Electricity Connection From Your Home.",
   "Hassel Fee Service.",
  ],
  ratings: "5",
  dummyPriceMrp: "6299",
  dummyPriceActual: "5399",
  maxQuantity: 1,
  __v: 0,
 },
 {
  _id: "6788e2dfaf8e3b24b4faecec",
  categoryId: {
   _id: "677ec5d8b237ec487b659709",
   tag: "Trending Now",
   variantType: "Car",
   categoryName: "Car Inspection",
   categoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736361431976-10317150.png",
   createdAt: "2025-01-08T18:37:12.045Z",
   updatedAt: "2025-01-08T18:37:12.045Z",
   __v: 0,
  },
  subCategoryId: {
   _id: "677ed648b237ec487b6597c2",
   categoryId: "677ec5d8b237ec487b659709",
   subCategoryName: "Car Sacning",
   subCategoryImage:
    "https://car-expert-media.s3.ap-south-1.amazonaws.com/1736365640400-download%20%281%29.jpg",
   createdAt: "2025-01-08T19:47:20.501Z",
   updatedAt: "2025-01-08T19:47:20.501Z",
   __v: 0,
  },
  productName: "test",
  productImage:
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1737024060029-Screenshot%202025-01-08%20025042.png",
  productBannerImages: [
   "https://car-expert-media.s3.ap-south-1.amazonaws.com/1737024069819-Screenshot%202025-01-08%20025042.png",
  ],
  highlights: [
   {
    text: "Highlights-1",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1737024103845-Screenshot%202025-01-08%20025042.png",
    _id: "6788e2dfaf8e3b24b4faeced",
   },
   {
    text: "Highlights-1",
    icon:
     "https://car-expert-media.s3.ap-south-1.amazonaws.com/1737024110436-Screenshot%202025-01-08%20025042.png",
    _id: "6788e2dfaf8e3b24b4faecee",
   },
  ],
  tags: [
   {
    name: "Tags",
    value: "Tags",
    _id: "6788e2dfaf8e3b24b4faecef",
   },
  ],
  offerTag: [
   {
    name: "Tags",
    value: "Tags",
    _id: "6788e2dfaf8e3b24b4faecf0",
   },
  ],
  includedService: [
   {
    name: "Included Services",
    services: [
     {
      title: "Included Services",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1737024127234-Screenshot%202025-01-08%20025042.png",
      _id: "6788e2dfaf8e3b24b4faecf2",
     },
     {
      title: "Included Services",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1737024146122-Screenshot%202025-01-08%20025042.png",
      _id: "6788e2dfaf8e3b24b4faecf3",
     },
    ],
    _id: "6788e2dfaf8e3b24b4faecf1",
   },
   {
    name: "Included Services2",
    services: [
     {
      title: "Included Services2",
      image:
       "https://car-expert-media.s3.ap-south-1.amazonaws.com/1737024177561-Screenshot%202025-01-08%20025042.png",
      _id: "6788e2dfaf8e3b24b4faecf5",
     },
    ],
    _id: "6788e2dfaf8e3b24b4faecf4",
   },
  ],
  isTrending: false,
  additionalServices: [
   "Additional Services",
   "Additional Services",
   "Additional Services",
  ],
  stepsAfterBooking: [
   "Additional Services",
   "Additional Services",
   "Additional Services",
  ],
  ratings: "5",
  dummyPriceMrp: "5200",
  dummyPriceActual: "6985",
  maxQuantity: 1,
  __v: 0,
 },
];
