import React, { useEffect } from "react";
import Slider from "react-slick";
import { Box, Heading, Image, Text, Tooltip } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyData } from "../../redux/companySlice";

const CompanySlider = () => {
 const dispatch = useDispatch();
 const { data, loading, error } = useSelector((state) => state.company);

 useEffect(() => {
  dispatch(fetchCompanyData());
 }, [dispatch]);

 if (loading) return <p>Loading...</p>;
 if (error) return <p>Error: {error}</p>;

 const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 10,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  arrows: false,
  responsive: [
   {
    breakpoint: 1024,
    settings: {
     slidesToShow: 3,
     slidesToScroll: 1,
    },
   },
   {
    breakpoint: 768,
    settings: {
     slidesToShow: 2,
     slidesToScroll: 1,
    },
   },
   {
    breakpoint: 480,
    settings: {
     slidesToShow: 5,
     slidesToScroll: 1,
    },
   },
  ],
 };

 return (
  <Box
   p={5}
   maxWidth={{
    base: "90vw", // Small screens
    md: "85vw", // Medium screens
    lg: "80vw", // Large screens
   }}
   mx="auto"
  >
   <Heading textAlign="center" fontWeight={"600"} fontSize={"1.5rem"}>
    Original Spare Parts
   </Heading>
   <Text textAlign={"center"}>Extended Warranty with Car Expert</Text>
   <Slider {...settings}>
    {data?.map((company) => (
     <Box
      bg={"transparent"}
      key={company.name}
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={2}
      _hover={{ transform: "scale(1.05)", transition: "0.3s ease-in-out" }}
     >
      <Tooltip label={company.name} hasArrow>
       <Box>
        <Image
         overflow={"hidden"}
         bg="transparent"
         src={company.logoUrl}
         alt={company.name}
         boxSize={{ base: "70px", md: "100px" }}
         objectFit="contain"
         borderRadius="md"
        />
       </Box>
      </Tooltip>
     </Box>
    ))}
   </Slider>
  </Box>
 );
};

export default CompanySlider;
