import React, { useEffect, useState } from "react";
import {
 Box,
 Heading,
 Text,
 Image,
 Flex,
 SimpleGrid,
 Drawer,
 DrawerOverlay,
 DrawerContent,
 DrawerBody,
 DrawerFooter,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalBody,
 Button,
 useBreakpointValue,
 border,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, fetchCart } from "../../../redux/cartSlice";
import CompanySlider from "../../Home/CompanySlider";
import { fetchCompanyData } from "../../../redux/companySlice";

const ProductDetailsPage = ({ product = {}, isOpen, onClose }) => {
 const isMobile = useBreakpointValue({ base: true, md: false }); // Detect screen size
 const [touchStart, setTouchStart] = useState(0); // Track touch start position
 const [touchEnd, setTouchEnd] = useState(0); // Track touch end position
 const navigate = useNavigate();
 const [currentStep, setCurrentStep] = useState(0); // Manage steps for the Car Selection Form
 const [selectedProduct, setSelectedProduct] = useState();
 const dispatch = useDispatch();
 const { cart } = useSelector((state) => state.cart);
 const { user, isAuth } = useSelector((state) => state.auth);
 const { selectedCar } = useSelector((state) => state.models);
 //  const { data, loading, error } = useSelector((state) => state.company);

 useEffect(() => {
  //   dispatch(fetchCompanyData());
 }, [dispatch]);

 const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  arrows: false,
  responsive: [
   {
    breakpoint: 1024,
    settings: {
     slidesToShow: 2,
     slidesToScroll: 1,
    },
   },
   {
    breakpoint: 768,
    settings: {
     slidesToShow: 1,
     slidesToScroll: 1,
    },
   },
  ],
 };
 const sliderSettings = {
  fade: true,
  speed: 2000,
  autoplaySpeed: 3000,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  pauseOnHover: false,
 };

 // Handle touch start
 const handleTouchStart = (e) => {
  setTouchStart(e.touches[0].clientY); // Record the Y position where touch starts
 };

 // Handle touch move
 const handleTouchMove = (e) => {
  setTouchEnd(e.touches[0].clientY); // Record the Y position where touch moves
 };

 // Handle touch end
 const handleTouchEnd = () => {
  if (touchStart - touchEnd < -50) {
   // Detect downward swipe if the difference is greater than 50px
   onClose();
  }
 };

 const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
 };
 const content = (
  <Box w="100%" fontFamily="'Poppins', sans-serif" p={4}>
   {/* Product Header Section */}
   <Box textAlign="center" mb={6}>
    <Heading
     as="h1"
     fontSize={["2xl", "3xl"]}
     fontWeight="bold"
     color="blue.800"
     textShadow="1px 1px 2px rgba(0, 0, 0, 0.2)"
    >
     {capitalizeFirstLetter(product?.productName)}
    </Heading>
    <Text fontSize="lg" color="gray.600" mt={2}>
     ⭐ {product?.ratings} | MRP:{" "}
     <Text as="s" display="inline" color="gray.500">
      ₹{product?.mrp}
     </Text>{" "}
     | Price:{" "}
     <Text as="span" fontWeight="bold" color="green.600">
      ₹{product?.price}
     </Text>
    </Text>
   </Box>

   {/* Banner Section */}
   {product?.productBannerImages?.length > 0 ? (
    <Slider {...sliderSettings}>
     {product?.productBannerImages.map((imageUrl, index) => (
      <Image
       key={index}
       src={imageUrl}
       alt={`Product Banner ${index + 1}`}
       w="100%"
       h="auto"
       borderRadius="lg"
       boxShadow="md"
       objectFit="cover"
       transition="all 0.3s"
       _hover={{
        transform: "scale(1.05)",
       }}
      />
     ))}
    </Slider>
   ) : (
    <Text textAlign="center" color="red.500">
     No banner images available
    </Text>
   )}

   {/* Highlights Section */}
   <Box mt={10}>
    <Heading as="h2" size="lg" mb={6} color="blue.700">
     Highlights
    </Heading>
    <SimpleGrid columns={[1, 2, 3]} spacing={6}>
     {product?.highlights?.map((highlight, index) => (
      <Flex
       key={index}
       align="center"
       gap={4}
       bg="gray.100"
       p={4}
       borderRadius="lg"
       boxShadow="sm"
       _hover={{
        boxShadow: "lg",
        transform: "translateY(-2px)",
       }}
       transition="all 0.3s"
      >
       <CheckCircleIcon color="green.500" boxSize={5} />
       <Text fontSize="md" color="gray.800">
        {highlight?.text}
       </Text>
      </Flex>
     ))}
    </SimpleGrid>
   </Box>

   {/* Additional Services Section */}
   <Box mt={10}>
    <Heading as="h2" size="lg" mb={6} color="blue.700">
     Additional Services
    </Heading>
    <SimpleGrid columns={[1, 2, 3]} spacing={6}>
     {product?.additionalServices?.map((service, index) => (
      <Flex
       key={index}
       align="center"
       gap={4}
       bg="white"
       p={4}
       borderRadius="lg"
       boxShadow="sm"
       border="1px solid #e2e8f0"
       _hover={{
        boxShadow: "lg",
        transform: "translateY(-2px)",
       }}
       transition="all 0.3s"
      >
       <CheckCircleIcon color="blue.500" boxSize={5} />
       <Text fontSize="md" color="gray.800">
        {service}
       </Text>
      </Flex>
     ))}
    </SimpleGrid>
   </Box>

   {/* Steps After Booking Section */}
   <Box mt={10} p={4} border="1px solid #e2e8f0" borderRadius="lg" bg="gray.50">
    <Heading as="h2" size="lg" mb={6} color="blue.700">
     Steps After Booking
    </Heading>
    <SimpleGrid columns={[1, 2]} spacing={6}>
     {product?.stepsAfterBooking?.map((step, index) => (
      <Flex
       key={index}
       align="center"
       gap={4}
       bg="white"
       p={4}
       borderRadius="lg"
       boxShadow="sm"
       _hover={{
        boxShadow: "lg",
        transform: "translateY(-2px)",
       }}
       transition="all 0.3s"
      >
       <Box
        as="span"
        bg="blue.500"
        color="white"
        w="32px"
        h="32px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="full"
        fontWeight="bold"
        fontSize="md"
        textShadow="1px 1px 2px rgba(0, 0, 0, 0.2)"
       >
        {index + 1}
       </Box>
       <Text fontSize="md" color="gray.800">
        {step}
       </Text>
      </Flex>
     ))}
    </SimpleGrid>
   </Box>
  </Box>
 );

 //  const content = (
 //   <Box w="100%" fontFamily="Arial, sans-serif" lineHeight="1" p={-5}>
 //    {/* Banner Section */}
 //    <Box w="100%" overflow="hidden">
 //     <Heading boxShadow="2xl" as="h1" fontSize="2xl" textAlign="center">
 //      {capitalizeFirstLetter(product?.productName)}
 //     </Heading>

 //     <Text fontSize="lg" color="gray.500" mt="2" textAlign="center">
 //      ⭐ {product?.ratings} | MRP:{" "}
 //      <Text as="s" display="inline">
 //       ₹{product?.mrp}
 //      </Text>{" "}
 //      | Price:{" "}
 //      <Text as="span" fontWeight="bold" color="green.600">
 //       ₹{product?.price}
 //      </Text>
 //     </Text>
 //     {product?.productBannerImages?.length > 0 ? (
 //      <Slider {...sliderSettings}>
 //       {product?.productBannerImages?.map((imageUrl, index) => (
 //        <Image
 //         key={index}
 //         src={imageUrl}
 //         alt={`Product Banner ${index + 1}`}
 //         w="100%" // full width
 //         // h="500px" // maintain aspect ratio
 //         borderRadius="md" // adjust corner rounding
 //         // border="2px solid"

 //         boxShadow="lg"
 //         objectFit="cover" // how the image should fit its container
 //        />
 //        //    <Image
 //        //     key={index}
 //        //     src={imageUrl}
 //        //     alt={`Product Banner ${index + 1}`}
 //        //     w="100%" // Ensure the image takes the full width
 //        //     h="auto" // Maintain aspect ratio
 //        //     borderRadius="md" // Remove border radius if not required
 //        //    />
 //       ))}
 //      </Slider>
 //     ) : (
 //      <Text>No banner images available</Text>
 //     )}
 //    </Box>

 //    <Box>
 //     {/* Highlights Section */}
 //     <Box mt={5}>
 //      <Heading as="h2" size="md" mb="4">
 //       Highlights
 //      </Heading>
 //      <SimpleGrid columns={[1, 3]} spacing={4}>
 //       {product?.highlights?.map((highlight, index) => (
 //        <Flex
 //         h="60px"
 //         key={index}
 //         alignItems="center"
 //         gap="2"
 //         bg="gray.100"
 //         p="4"
 //         borderRadius="md"
 //         boxShadow="sm"
 //        >
 //         <CheckCircleIcon color="red.500" />
 //         <Text fontSize="md" mt={4}>
 //          {highlight?.text}
 //         </Text>
 //        </Flex>
 //       ))}
 //      </SimpleGrid>
 //     </Box>

 //     <Box>
 //      <Heading as="h2" size="md" mb="4">
 //       Additional Services
 //      </Heading>
 //      <SimpleGrid columns={[1, 3]} spacing={4}>
 //       {product?.additionalServices?.map((service, index) => (
 //        <Flex
 //         key={index}
 //         align="center"
 //         gap="2"
 //         bg="gray.50"
 //         p="4"
 //         borderRadius="md"
 //         boxShadow="sm"
 //         h="50px"
 //        >
 //         <CheckCircleIcon color="red.500" />
 //         <Text fontSize="md" mt={5}>
 //          {service}
 //         </Text>
 //        </Flex>
 //       ))}
 //      </SimpleGrid>
 //     </Box>
 //    </Box>

 //    {/* Steps After Booking Section */}
 //    <Box p={4} mt={10} border="1px solid #e2e8f0" borderRadius="md">
 //     <Heading as="h2" size="md" mb="4">
 //      Steps After Booking
 //     </Heading>
 //     <SimpleGrid columns={[1, 2]} spacing={4}>
 //      {product?.stepsAfterBooking?.map((step, index) => (
 //       <Flex
 //        key={index}
 //        align="center"
 //        gap="4"
 //        bg="gray.50"
 //        p="4"
 //        borderRadius="md"
 //        boxShadow="sm"
 //       >
 //        <Box
 //         as="span"
 //         bg="red.500"
 //         color="white"
 //         w="24px"
 //         h="24px"
 //         display="flex"
 //         alignItems="center"
 //         justifyContent="center"
 //         borderRadius="full"
 //         fontWeight="bold"
 //        >
 //         {index + 1}
 //        </Box>
 //        <Text mt={5} h={"30px"} color="gray.800">
 //         {step}
 //        </Text>
 //       </Flex>
 //      ))}
 //     </SimpleGrid>
 //    </Box>
 //   </Box>
 //  );
 const animationVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 50 },
 };
 const handleAddToCart = (product, ProductType) => {
  // Safely destructure `product`
  const productId = product?._id || null;
  const subCategoryId = product?.subCategoryId?._id || null;

  // Safely destructure `user`
  const currentCarId = user?.currentCar?._id || selectedCar;
  const userId = user?._id || null;

  // Log the prepared data
  console.log("Prepared Data:", {
   productId,
   subCategoryId,
   currentCarId,
   userId,
   ProductType,
   selectedCar,
  });

  // Ensure all required fields are present
  if (productId && subCategoryId && currentCarId && userId && ProductType) {
   // Dispatch actions if all fields are valid
   dispatch(
    addToCart({
     productId,
     subCategoryId,
     currentCarId,
     userId,
     ProductType,
    })
   );
   dispatch(fetchCart(userId));
   navigate("/product-listing-page");
  } else {
   // Log missing fields for debugging
   console.error("Missing required fields:", {
    productId,
    subCategoryId,
    currentCarId,
    userId,
    ProductType,
   });
  }
 };
 //  console.log("product:", product);
 return isMobile ? (
  // Drawer for mobile
  //   <Drawer
  //    isOpen={isOpen}
  //    placement="bottom"
  //    onClose={onClose}
  //    onTouchStart={handleTouchStart} // Listen for touch start
  //    onTouchMove={handleTouchMove} // Listen for touch move
  //    onTouchEnd={handleTouchEnd} // Listen for touch end
  //   >
  //    <DrawerOverlay />
  //    <DrawerContent
  //     sx={{
  //      height: "80vh",
  //      borderTopRadius: "lg",
  //      boxShadow: "xl",
  //     }}
  //     exit="exit"
  //     variants={animationVariants}
  //     transition={{ duration: 0.5 }}
  //     drag="y"
  //     dragConstraints={{ top: 0, bottom: 100 }}
  //     onDragEnd={(event, info) => {
  //      if (info.offset.y > 100) {
  //       onClose(); // Close the drawer on swipe down
  //      }
  //     }}
  //     bg="white"
  //     borderTopRadius="lg"
  //     p={4}
  //     boxShadow="lg"
  //    >
  //     <DrawerBody>{content}</DrawerBody>
  //     <DrawerFooter>
  //      <Button
  //       bgColor={"#000D6B"}
  //       color={"#FFFF"}
  //       size="md"
  //       variant="outline"
  //       onClick={() => handleAddToCart(product, "Product")}
  //       //   onClick={onClose}
  //       w="full"
  //      >
  //       Book Now
  //      </Button>
  //     </DrawerFooter>
  //    </DrawerContent>
  //   </Drawer>

  <Drawer
   isOpen={isOpen}
   placement="bottom"
   onClose={onClose}
   onTouchStart={handleTouchStart} // Listen for touch start
   onTouchMove={handleTouchMove} // Listen for touch move
   onTouchEnd={handleTouchEnd} // Listen for touch end
  >
   <DrawerOverlay />
   <DrawerContent
    sx={{
     height: "85vh", // Take most of the vertical screen
     borderTopRadius: "2xl", // Smooth rounded corners
     boxShadow: "xl",
    }}
    bg="gray.50" // Light background for better contrast
    p={4}
    drag="y"
    dragConstraints={{ top: 0, bottom: 100 }}
    onDragEnd={(event, info) => {
     if (info.offset.y > 100) {
      onClose(); // Close the drawer on swipe down
     }
    }}
   >
    {/* Drawer Body */}
    <DrawerBody p={0}>
     {/* Banner Section */}
     <Box
      w="100%"
      overflow="hidden"
      bg="white"
      borderRadius="lg"
      boxShadow="md"
     >
      <Slider {...sliderSettings}>
       {product?.productBannerImages?.map((imageUrl, index) => (
        <Image
         key={index}
         src={imageUrl}
         alt={`Product Banner ${index + 1}`}
         w="100%" // Full width
         h="200px" // Fixed height for a neat layout
         objectFit="cover"
        />
       ))}
      </Slider>
     </Box>

     {/* Content Section */}
     <Box p={4}>
      {/* Title */}
      <Heading
       fontSize="lg"
       color="blue.800"
       textAlign="center"
       fontWeight="bold"
      >
       {capitalizeFirstLetter(product?.productName)}
      </Heading>

      {/* Rating and Pricing */}
      <Text textAlign="center" fontSize="md" color="gray.600" mt={2}>
       ⭐ {product?.ratings} | MRP:{" "}
       <Text as="s" color="gray.500">
        ₹{product?.mrp}
       </Text>{" "}
       | Price:{" "}
       <Text as="span" fontWeight="bold" color="green.600">
        ₹{product?.price}
       </Text>
      </Text>
      {/* Highlights Section */}
      <Box mt={4}>
       <Heading as="h2" size="sm" color="blue.700" mb={2}>
        Highlights
       </Heading>
       <SimpleGrid columns={[1, 2]} spacing={3}>
        {product?.highlights?.map((highlight, index) => (
         <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          key={highlight._id || index} // Use a unique key
          bg="white"
          p={3}
          borderRadius="md"
          boxShadow="sm"
         >
          <img
           src={`${highlight?.icon}`}
           alt="Highlight Icon"
           style={{
            width: "30px",
            height: "30px",
            marginRight: "8px",
           }}
          />
          {/* <CheckCircleIcon color="green.500" mr={2} /> */}
          <Text p="1" fontSize="sm" color="gray.800">
           {highlight.text} {/* Ensure only text is rendered */}
          </Text>
         </Box>
        ))}
       </SimpleGrid>
      </Box>
      <CompanySlider />

      <Box
       p={5}
       maxWidth={{
        base: "90vw", // Small screens
        md: "85vw", // Medium screens
        lg: "80vw", // Large screens
       }}
       mx="auto"
       lineHeight={1}
      >
       <Heading textAlign="center" fontWeight="600" fontSize="1.5rem" mb={3}>
        Exciting Offers
       </Heading>
       <Text textAlign="center" mb={6}>
        Grab amazing discounts with our partners
       </Text>
       <Slider {...settings}>
        {[
         {
          _id: "66c8549745f463615cc7301a",
          title: "Festive20",
          code: "Carexpertfes20",
          discountPercent: 20,
          description: "Get 20% cashback",
          subCategory: null,
          type: "discount",
          expiryDate: "2024-09-30T00:00:00.000Z",
          isActive: true,
          createdAt: "2024-08-23T09:21:27.791Z",
          updatedAt: "2024-09-16T19:04:01.406Z",
          __v: 0,
         },
         {
          _id: "66cdba565b36a9d3ab9bacc4",
          title: "Flat 10",
          code: "CEFlat10",
          discountPercent: 10,
          description: "Get 10% Flat  Discount On Your Order",
          subCategory: null,
          type: "discount",
          expiryDate: "2025-01-31T00:00:00.000Z",
          isActive: true,
          createdAt: "2024-08-27T11:36:54.468Z",
          updatedAt: "2025-01-06T09:56:05.609Z",
          __v: 0,
         },
        ]?.map((offer, index) => (
         <Box
          key={index}
          display="flex"
          justifyContent="center"
          alignItems="center"
          px={2}
         >
          {/* Ticket Card */}
          <Box
           position="relative"
           border="2px solid #000D6B"
           borderRadius="md"
           bg="white"
           w="280px"
           p={4}
           textAlign="center"
           clipPath="polygon(10% 0, 90% 0, 100% 10%, 100% 90%, 90% 100%, 10% 100%, 0 90%, 0 10%)"
           boxShadow="lg"
          >
           <Box lineHeight={1}>
            <Text fontWeight="bold" fontSize="lg" color="#000D6B" mb={2}>
             {offer.title || "Get Flat 10%"}
            </Text>
            <Box
             display={"flex"}
             alignItems={"center"}
             justifyContent={"center"}
             //  border={"1px solid red"}
            >
             <img
              src={`/discount.svg`}
              alt="Highlight Icon"
              style={{
               width: "30px",
               height: "30px",
               marginRight: "8px",
              }}
             />

             <Text fontWeight="" fontSize="lg" color="#000D6B" mb={2}>
              {offer.code || "Get Flat 10%"}
             </Text>
            </Box>
            <Text fontSize="sm" color="gray.600">
             {offer.description || "Smart Discount for Smart Drivers"}
            </Text>
           </Box>
          </Box>
         </Box>
        ))}
       </Slider>
      </Box>
      {/* Additional Services Section */}
      <Box mt={6}>
       <Heading as="h2" size="sm" color="blue.700" mb={2}>
        Additional Services
       </Heading>
       <SimpleGrid columns={[1, 2]} spacing={3}>
        {product?.additionalServices?.map((service, index) => (
         <Flex
          key={service._id || index} // Use a unique key
          align="center"
          bg="gray.100"
          p={3}
          borderRadius="md"
          boxShadow="sm"
         >
          <CheckCircleIcon color="blue.500" mr={2} />
          <Text mt={4} fontSize="sm" color="gray.800">
           {service} {/* Ensure only text is rendered */}
          </Text>
         </Flex>
        ))}
       </SimpleGrid>
      </Box>
      <Box
       mt={6}
       p={6}
       border="1px solid #e2e8f0"
       borderRadius="lg"
       bg="white"
       boxShadow="sm"
      >
       <Heading as="h2" size="md" color="gray.700" mb={6}>
        Steps After Booking
       </Heading>
       <Box>
        {product?.stepsAfterBooking?.map((step, index) => (
         <Flex key={index} mb={4} align="flex-start">
          {/* Step Number in Circle */}
          <Box
           //    bg="purple.100"
           color="purple.600"
           //    w="50px"
           //    h="30px"
           //    display="flex"
           //    alignItems="center"
           //    justifyContent="center"
           //    borderRadius="full"
           fontWeight="bold"
           //    fontSize="sm"
           mr={4}
          >
           <Text>{index + 1}.</Text>
          </Box>
          {/* Step Description */}
          <Text fontSize="sm" color="gray.600">
           {step}
          </Text>
         </Flex>
        ))}
       </Box>
      </Box>
     </Box>
    </DrawerBody>

    {/* Drawer Footer */}
    <DrawerFooter borderTop="1px solid #e2e8f0" bg="white" p={4}>
     <Button
      bgColor={"#000D6B"}
      color={"#FFFF"}
      size="lg"
      w="full"
      borderRadius="md"
      _hover={{ bg: "#002A9F" }}
      onClick={() => handleAddToCart(product, "Product")}
     >
      Book Now
     </Button>
    </DrawerFooter>
   </DrawerContent>
  </Drawer>
 ) : (
  <Modal isOpen={isOpen} onClose={onClose} size="5xl">
   <ModalOverlay />
   <ModalContent
    p="5"
    position="relative" // Ensures content stays within the modal
    maxHeight="90vh"
    overflowY="auto"
    borderRadius="lg"
    boxShadow="2xl"
    sx={{
     "::-webkit-scrollbar": {
      display: "none", // Hide scrollbar for Webkit browsers
     },
     msOverflowStyle: "none", // IE and Edge
     scrollbarWidth: "none", // Firefox
    }}
   >
    <ModalBody>{content}</ModalBody>

    {/* Footer Section for Button */}
    <Box
     w="full"
     bg="white" // Ensures clear separation from content
     py={4}
     borderTop="1px solid #e2e8f0" // Subtle top border for separation
     display="flex"
     justifyContent="center"
     position="sticky" // Keeps button visible while scrolling
     bottom="0"
    >
     <Button
      size="lg"
      bgColor="#000D6B"
      color="#FFFF"
      onClick={() => handleAddToCart(product, "Product")}
      w="50%" // Center the button and make it reasonably wide
      borderRadius="md"
      _hover={{ bg: "#002A9F" }} // Optional hover effect
      aria-label="Book Now"
     >
      Book Now
     </Button>
    </Box>
   </ModalContent>
  </Modal>

  //   <Modal isOpen={isOpen} onClose={onClose} size="5xl">
  //    <ModalOverlay />
  //    <ModalContent
  //     p="5"
  //     position="absolute"
  //     // top="10px"
  //     // maxHeight="90vh"
  //     overflowY="auto"
  //     borderRadius="lg"
  //     boxShadow="2xl"
  //     sx={{
  //      "::-webkit-scrollbar": {
  //       display: "none", // Hide scrollbar for Webkit browsers
  //      },
  //      msOverflowStyle: "none", // IE and Edge
  //      scrollbarWidth: "none", // Firefox
  //     }}
  //    >
  //     <ModalBody>{content}</ModalBody>

  //     {/* Button Positioned at the Modal Bottom */}
  //     <Box
  //      ml={"140px"}
  //      w="50%"
  //      position="fixed" // Ensures the button sticks to the bottom of the modal
  //      bottom="0"
  //      bg="transparent" // Background to separate it from content
  //      py={4}
  //     >
  //      <Button
  //       size="lg"
  //       bgColor="#000D6B"
  //       color={"#FFFF"}
  //       //   onClick={onClose}
  //       onClick={() => handleAddToCart(product, "Product")}
  //       w="full"
  //       borderRadius="md"
  //      >
  //       Book Now
  //      </Button>
  //     </Box>
  //    </ModalContent>
  //   </Modal>
 );
};

export default ProductDetailsPage;
