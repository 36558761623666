import React, { Suspense, lazy, useRef } from "react";
const HeroSlider = lazy(() => import("../Home/TopSection/HeroSlider"));
const ServicesPage = lazy(() => import("../UI/ServiceSection/ServicesPage"));
const BrandSlider = lazy(() => import("./BrandSlider"));
const CompanySlider = lazy(() => import("../Home/CompanySlider"));
const UserReviewsSlider = lazy(() => import("../Home/UserReviewsSlider"));
const TestimonialVideoSlider = lazy(() =>
 import("../Home/VideoTestimonialsSlider")
);
const PartnerReviewsSlider = lazy(() => import("../Home/PartnerReviewsSlider"));
const FaqAccordion = lazy(() => import("../UI/FaqAccordion"));

const Home = () => {
 const servicesRef = useRef(); // Create a ref for the ServicesPage section

 // Handle click on a trending product

 const handleTrendingProductClick = ({ categoryId, subCategoryId }) => {
  if (servicesRef.current) {
   // Update category and subcategory
   servicesRef.current.updateCategoryAndSubCategory(categoryId, subCategoryId);

   // Scroll to the ServicesPage section
   servicesRef.current.scrollIntoView({ behavior: "smooth" });
  }
 };

 return (
  <Suspense>
   <HeroSlider onProductClick={handleTrendingProductClick} />
   <ServicesPage ref={servicesRef} />
   <BrandSlider />
   <CompanySlider />
   <UserReviewsSlider />
   <TestimonialVideoSlider />
   <PartnerReviewsSlider />
   <FaqAccordion />
  </Suspense>
 );
};

export default Home;
