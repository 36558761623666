import React, { useEffect } from "react";
import Slider from "react-slick";
import {
 Box,
 Heading,
 Text,
 Image,
 VStack,
 Card,
 Stack,
 CardBody,
 CardFooter,
 HStack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
 fetchSparePartsProducts,
 fetchTrendingProducts,
} from "../../redux/tranding";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { addToCart, fetchCart } from "../../redux/cartSlice";

const OriginalSpareParts = () => {
 const dispatch = useDispatch();
 const { cart, status, error: cartError } = useSelector((state) => state.cart);
 const { user, isAuth } = useSelector((state) => state.auth); // Redux user state

 const { trendingProducts, spareParts, loading, error } = useSelector(
  (state) => state.trending
 );

 useEffect(() => {
  dispatch(fetchTrendingProducts());
  dispatch(fetchSparePartsProducts());
 }, []);
 //  console.log("trendingProducts:", trendingProducts);
 const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of products to show in a row
  slidesToScroll: 3,
  responsive: [
   {
    breakpoint: 1024,
    settings: {
     slidesToShow: 3,
     slidesToScroll: 3,
    },
   },
   {
    breakpoint: 768,
    settings: {
     slidesToShow: 2,
     slidesToScroll: 2,
    },
   },
   {
    breakpoint: 480,
    settings: {
     slidesToShow: 1,
     slidesToScroll: 1,
    },
   },
  ],
 };
 const handleAddToCart = (product, ProductType) => {
  console.log("product:", product);
  const { _id: productId } = product;
  const { SubCategory: subCategoryId } = product;
  const {
   currentCar: { _id: currentCarId },
   _id: userId,
  } = user;
  // Log data to ensure correctness
  console.log("Prepared Data:", {
   productId,
   subCategoryId,
   currentCarId,
   userId,
   ProductType,
  });
  if (productId) {
   console.log("subCategoryId:", product.SubCategory);
   dispatch(
    addToCart({
     productId,
     subCategoryId,
     currentCarId,
     userId,
     ProductType,
    }) // Pass as an object
   );
   dispatch(fetchCart(userId));
  } else {
   console.error("Missing required fields:", {
    productId,
    subCategoryId,
    currentCarId,
    userId,
   });
  }
 };
 const isProductInCart = (productId) => {
  return cart?.items?.some((item) => item.productId === productId);
 };
 if (loading) {
  return <Text>Loading...</Text>;
 }

 if (error) {
  return <Text>Error loading products: {error}</Text>;
 }

 return (
  <Box mt={10}>
   <Heading as="h2" size="lg" mb={4} textAlign="center">
    Spare Parts
   </Heading>
   <Text size="lg" mt={-3} textAlign="center">
    Extended Warranty with Car Expert
   </Text>
   <Text size="lg" mt={-3} textAlign="center">
    Original Spare Parts
   </Text>
   <Slider {...settings}>
    {spareParts.map((sparePart, index) => (
     <Box
      position="relative"
      w="300px"
      h="300px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
     >
      {/* Image Section */}
      <Image
       src={sparePart.image || "https://via.placeholder.com/200?text=No+Image"}
       alt={sparePart.spareName}
       objectFit="cover"
       w="100%"
       h="100%"
      />

      {/* Content Overlay */}
      <Box
       position="absolute"
       top="0"
       left="0"
       w="100%"
       h="100%"
       bg="rgba(0, 0, 0, 0.5)" // Transparent black background
       color="white"
       display="flex"
       flexDirection="column"
       justifyContent="center"
       alignItems="center"
       textAlign="center"
       p={4}
      >
       <Heading size="md" mb={2}>
        {sparePart.spareName}
       </Heading>
       <Text fontSize="sm" color="gray.200" mb={2}>
        MRP: ₹{sparePart.mrp}
       </Text>
       <Text fontSize="lg" fontWeight="bold" color="green.300" mb={4}>
        Price: ₹{sparePart.price}
       </Text>
       {isAuth ? (
        <Link to={"/product-listing-page"}>
         <Button
          colorScheme="blue"
          size="md"
          _hover={{ bg: "blue.600" }}
          onClick={() => handleAddToCart(sparePart, "SparePart")}
         >
          {isProductInCart(sparePart._id) ? "Already in Cart" : "Buy Now"}
         </Button>
        </Link>
       ) : (
        <Button
         colorScheme="teal"
         variant="outline"
         size="md"
         _hover={{ bg: "teal.100" }}
        >
         Check Price
        </Button>
       )}
      </Box>
     </Box>
    ))}
   </Slider>
  </Box>
 );
};

export default OriginalSpareParts;
