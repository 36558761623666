import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useParams } from "react-router-dom";

const defaultCategoryName = "Car Deep Cleaning"; // Default category
const defaultSubCategoryName = "Interior Deep Cleaning"; // Default subcategory

// Fetch all categories and auto-select default category
export const fetchCategories = createAsyncThunk(
 "services/fetchCategories",
 async (_, { dispatch }) => {
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/categories/all"
  );
  const categories = response.data;

  // Auto-select default category
  const defaultCategory = categories.find(
   (category) => category.categoryName === defaultCategoryName
  );

  if (defaultCategory) {
   dispatch(selectCategory(defaultCategory._id));
   dispatch(fetchSubCategories(defaultCategory._id));
  }

  return categories;
 }
);

// Fetch subcategories for a given category and auto-select default subcategory
export const fetchSubCategories = createAsyncThunk(
 "services/fetchSubCategories",
 async (categoryId, { dispatch }) => {
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/subcategories/all"
  );
  const filteredSubCategories = response.data.filter(
   (subCat) => subCat.categoryId._id === categoryId
  );

  // Auto-select default subcategory if available
  const defaultSubCategory = filteredSubCategories.find(
   (subCategory) => subCategory.subCategoryName === defaultSubCategoryName
  );

  if (defaultSubCategory) {
   dispatch(selectSubCategory(defaultSubCategory._id));
   dispatch(fetchProducts({ subCategoryId: defaultSubCategory._id }));
  }

  return { categoryId, subCategories: filteredSubCategories };
 }
);

export const fetchProducts = createAsyncThunk(
 "services/fetchProducts",
 async ({ subCategoryId, carId }) => {
  const defaultCarId =
   JSON.parse(localStorage.getItem("userDetails"))?.currentCar?._id || null;
  // console.log("subCategoryId:", subCategoryId);
  const response = await axios.get(
   `https://app-api.carexpert.org.in/api/price/products-by-subcategory/${subCategoryId}`,
   {
    params: { carId: carId || defaultCarId },
   }
  );

  return { subCategoryId, products: response.data };
 }
);
export const fetchCategoriesAndSubCategories = createAsyncThunk(
 "services/fetchCategoriesAndSubCategories",
 async (_, { dispatch }) => {
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/categories"
  );
  const { categories, subCategories } = response.data;

  if (categories.length > 0) {
   const firstCategory = categories[0];
   dispatch(selectCategory(firstCategory._id));

   const firstSubCategory = subCategories.find(
    (sub) => sub.categoryId === firstCategory._id
   );
   //  console.log("firstSubCategory:", firstSubCategory);

   if (firstSubCategory) {
    dispatch(selectSubCategory(firstSubCategory._id));
    dispatch(fetchProducts(firstSubCategory._id));
   }
  }

  return { categories, subCategories };
 }
);
// Fetch products for listing page with dynamic loading
export const fetchProductsForListing = createAsyncThunk(
 "services/fetchProductsForListing",
 async ({ categoryId, activeSubCategoryIndex }, { getState, dispatch }) => {
  const state = getState();
  const subCategories = state.services.subCategories[categoryId] || [];
  const nextSubCategory = subCategories[activeSubCategoryIndex];

  if (nextSubCategory) {
   const result = await dispatch(
    fetchProducts({ subCategoryId: nextSubCategory._id })
   );
   if (fetchProducts.rejected.match(result)) {
    throw new Error(
     `Failed to fetch products for subCategoryId: ${nextSubCategory._id}`
    );
   }
  }
 }
);

const servicesSlice = createSlice({
 name: "services",
 initialState: {
  products: {},
  categories: [],
  subCategories: [],
  selectedCategory: null,
  selectedSubCategory: null,
  loading: false,
  error: null,
 },
 reducers: {
  selectCategory: (state, action) => {
   state.selectedCategory = action.payload;
  },
  selectSubCategory: (state, action) => {
   //  console.log("action.payload:", action.payload);
   state.selectedSubCategory = action.payload;
  },
  clearProducts: (state) => {
   state.products = {}; // Clears all products in the state
  },
 },
 extraReducers: (builder) => {
  builder
   // Fetch Categories
   .addCase(fetchCategories.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchCategories.fulfilled, (state, action) => {
    state.loading = false;
    state.categories = action.payload;
   })
   .addCase(fetchCategories.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   })

   // Fetch Subcategories
   .addCase(fetchSubCategories.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchSubCategories.fulfilled, (state, action) => {
    const { categoryId, subCategories } = action.payload;
    state.loading = false;
    state.subCategories[categoryId] = subCategories;
   })
   .addCase(fetchSubCategories.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   });
  builder
   .addCase(fetchCategoriesAndSubCategories.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchCategoriesAndSubCategories.fulfilled, (state, action) => {
    state.loading = false;
    state.categories = action.payload.categories;
    state.subCategories = action.payload.subCategories;
   })
   .addCase(fetchCategoriesAndSubCategories.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   })

   // Fetch Products
   .addCase(fetchProducts.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchProducts.fulfilled, (state, action) => {
    const { subCategoryId, products } = action.payload;
    state.loading = false;
    state.products[subCategoryId] = products;
   })
   .addCase(fetchProducts.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   });
  builder
   .addCase(fetchProductsForListing.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchProductsForListing.fulfilled, (state) => {
    state.loading = false; // Products already updated by fetchProducts
   })
   .addCase(fetchProductsForListing.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   });
 },
});

export const { selectCategory, selectSubCategory, clearProducts } =
 servicesSlice.actions;

export default servicesSlice.reducer;
