import React, { useState, useEffect } from "react";
import {
 Box,
 Grid,
 Flex,
 Text,
 Heading,
 Button,
 IconButton,
 Divider,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { format, addDays, startOfToday, parse, isBefore } from "date-fns";
import AddressForm from "./AddressForm";

const TimeSlotPicker = () => {
 const [slots, setSlots] = useState([]);
 const [selectedDate, setSelectedDate] = useState(new Date());
 const [selectedTime, setSelectedTime] = useState(null);
 const [startIndex, setStartIndex] = useState(0); // Tracks the starting index of the 5-day range

 useEffect(() => {
  // Fetch time slots from the API
  fetch("https://app-api.carexpert.org.in/api/slots")
   .then((res) => res.json())
   .then((data) => setSlots(data))
   .catch((err) => console.error("Error fetching slots:", err));
 }, []);

 const today = startOfToday();
 const dates = Array.from({ length: 30 }, (_, i) => addDays(today, i)); // Generate 30 upcoming dates
 const displayedDates = dates.slice(startIndex, startIndex + 5); // Slice the array to show only 5 dates

 const handlePrevious = () => {
  if (startIndex > 0) setStartIndex((prev) => prev - 5);
 };

 const handleNext = () => {
  if (startIndex + 5 < dates.length) setStartIndex((prev) => prev + 5);
 };

 const handleBookSlot = (slotId) => {
  setSelectedTime(slotId);
 };

 return (
  <Box>
   <AddressForm />
   <Box maxW="900px" mx="auto" p={4} bg="gray.50" borderRadius="lg" shadow="lg">
    <Heading as="h3" size="lg" textAlign="center" mb={4}>
     📅 Schedule Your Slot
    </Heading>

    {/* Date Selector */}
    <Flex align="center" justify="space-between" mb={4} gap={2}>
     <IconButton
      icon={<ChevronLeftIcon />}
      aria-label="Previous days"
      onClick={handlePrevious}
      isDisabled={startIndex === 0}
      variant="outline"
      size="xs"
      colorScheme="blue"
     />
     <Grid templateColumns="repeat(5, 1fr)" gap={2} w="full" textAlign="center">
      {displayedDates.map((date) => {
       const isSelected = date.toDateString() === selectedDate.toDateString();
       return (
        <Box
         key={date}
         cursor="pointer"
         p={3}
         bg={isSelected ? "blue.500" : "white"}
         border="1px solid"
         borderColor={isSelected ? "blue.500" : "gray.200"}
         borderRadius="md"
         shadow={isSelected ? "md" : "sm"}
         onClick={() => setSelectedDate(date)}
         transition="all 0.2s"
        >
         <Text
          fontSize="sm"
          fontWeight="bold"
          color={isSelected ? "white" : "gray.800"}
         >
          {format(date, "EEE")}
         </Text>
         <Text fontSize="md" color={isSelected ? "white" : "gray.600"}>
          {format(date, "d MMM")}
         </Text>
        </Box>
       );
      })}
     </Grid>
     <IconButton
      icon={<ChevronRightIcon />}
      aria-label="Next days"
      onClick={handleNext}
      isDisabled={startIndex + 5 >= dates.length}
      variant="outline"
      size="xs"
      colorScheme="blue"
     />
    </Flex>

    {/* Time Slots */}
    <Box bg="white" borderRadius="md" p={4} mt={4} shadow="sm">
     <Text mb={2} fontWeight="bold" textAlign="center">
      Select a Time Slot
     </Text>
     <Divider mb={4} />
     <Grid
      templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
      gap={4}
     >
      {slots
       .filter(
        (slot) => slot.time && slot.time.trim() !== "" && slot.time !== "0"
       ) // Exclude invalid values
       .map((slot) => {
        const isSelected = selectedTime === slot._id;

        // Parse the slot time and check if it's in the past
        const slotDateTime = parse(slot.time, "h:mm a", selectedDate);
        const isPast = isBefore(slotDateTime, new Date());

        return (
         <Box
          key={slot._id}
          position="relative"
          bg={isSelected ? "blue.50" : "white"}
          border="1px solid"
          borderColor={isSelected ? "blue.500" : "gray.200"}
          borderRadius="md"
          shadow={isSelected ? "md" : "sm"}
          cursor={isPast ? "not-allowed" : "pointer"}
          onClick={() => {
           if (!isPast) {
            handleBookSlot(slot._id);
           }
          }}
          p={2}
          textAlign="center"
          w={{ base: "100px", md: "120px" }}
          h="50px"
          transition="all 0.2s"
          opacity={isPast ? 0.5 : 1} // Dim past slots
         >
          {/* Badge for Discount */}
          {slot.discount && (
           <Box position="absolute" top="-8px" left="-8px">
            <Box
             as="span"
             bg="blue.500"
             color="white"
             fontSize="10px"
             fontWeight="bold"
             borderRadius="full"
             px={2}
             py={0.5}
             shadow="sm"
            >
             {slot.discount}% OFF
            </Box>
           </Box>
          )}
          {/* Slot Time */}
          <Flex direction="column" align="center" justify="center" h="full">
           <Text fontSize="sm" fontWeight="bold" color="gray.800" mt={5}>
            {slot.time}
           </Text>
          </Flex>
         </Box>
        );
       })}
     </Grid>
    </Box>

    {/* Next Button */}
    {selectedTime && (
     <Flex justify="center" mt={6}>
      <Button colorScheme="blue" size="lg">
       Next
      </Button>
     </Flex>
    )}
   </Box>
  </Box>
 );
};

export default TimeSlotPicker;

// import React, { useState, useEffect } from "react";
// import {
//  Box,
//  Grid,
//  Flex,
//  Text,
//  Heading,
//  Button,
//  IconButton,
//  Divider,
// } from "@chakra-ui/react";
// import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
// import { format, addDays, startOfToday } from "date-fns";

// const TimeSlotPicker = () => {
//  const [slots, setSlots] = useState([]);
//  const [selectedDate, setSelectedDate] = useState(new Date());
//  const [selectedTime, setSelectedTime] = useState(null);
//  const [startIndex, setStartIndex] = useState(0); // Tracks the starting index of the 5-day range

//  useEffect(() => {
//   // Fetch time slots from the API
//   fetch("https://app-api.carexpert.org.in/api/slots")
//    .then((res) => res.json())
//    .then((data) => setSlots(data))
//    .catch((err) => console.error("Error fetching slots:", err));
//  }, []);

//  const today = startOfToday();
//  const dates = Array.from({ length: 30 }, (_, i) => addDays(today, i)); // Generate 30 upcoming dates
//  const displayedDates = dates.slice(startIndex, startIndex + 5); // Slice the array to show only 5 dates

//  const handlePrevious = () => {
//   if (startIndex > 0) setStartIndex((prev) => prev - 5);
//  };

//  const handleNext = () => {
//   if (startIndex + 5 < dates.length) setStartIndex((prev) => prev + 5);
//  };

//  const handleBookSlot = (slotId) => {
//   setSelectedTime(slotId);
//  };

//  return (
//   <Box maxW="900px" mx="auto" p={4} bg="gray.50" borderRadius="lg" shadow="lg">
//    <Heading as="h3" size="lg" textAlign="center" mb={4}>
//     📅 Schedule Your Slot
//    </Heading>

//    {/* Date Selector */}
//    <Flex align="center" justify="space-between" mb={4} gap={2}>
//     <IconButton
//      icon={<ChevronLeftIcon />}
//      aria-label="Previous days"
//      onClick={handlePrevious}
//      isDisabled={startIndex === 0}
//      variant="outline"
//      size="xs"
//      colorScheme="blue"
//     />
//     <Grid templateColumns="repeat(5, 1fr)" gap={2} w="full" textAlign="center">
//      {displayedDates.map((date) => {
//       const isSelected = date.toDateString() === selectedDate.toDateString();
//       return (
//        <Box
//         key={date}
//         cursor="pointer"
//         p={3}
//         bg={isSelected ? "blue.500" : "white"}
//         border="1px solid"
//         borderColor={isSelected ? "blue.500" : "gray.200"}
//         borderRadius="md"
//         shadow={isSelected ? "md" : "sm"}
//         onClick={() => setSelectedDate(date)}
//         transition="all 0.2s"
//        >
//         <Text
//          fontSize="sm"
//          fontWeight="bold"
//          color={isSelected ? "white" : "gray.800"}
//         >
//          {format(date, "EEE")}
//         </Text>
//         <Text fontSize="md" color={isSelected ? "white" : "gray.600"}>
//          {format(date, "d MMM")}
//         </Text>
//        </Box>
//       );
//      })}
//     </Grid>
//     <IconButton
//      icon={<ChevronRightIcon />}
//      aria-label="Next days"
//      onClick={handleNext}
//      isDisabled={startIndex + 5 >= dates.length}
//      variant="outline"
//      size="xs"
//      colorScheme="blue"
//     />
//    </Flex>

//    {/* Time Slots */}
//    <Box bg="white" borderRadius="md" p={4} mt={4} shadow="sm">
//     <Text mb={2} fontWeight="bold" textAlign="center">
//      Select a Time Slot
//     </Text>
//     <Divider mb={4} />
//     <Grid
//      templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
//      gap={4}
//     >
//      {slots
//       .filter(
//        (slot) => slot.time && slot.time.trim() !== "" && slot.time !== "0"
//       ) // Exclude invalid values
//       .map((slot) => {
//        const isSelected = selectedTime === slot._id;
//        return (
//         <Box
//          key={slot._id}
//          position="relative"
//          bg={isSelected ? "blue.50" : "white"}
//          border="1px solid"
//          borderColor={isSelected ? "blue.500" : "gray.200"}
//          borderRadius="md"
//          shadow={isSelected ? "md" : "sm"}
//          cursor="pointer"
//          onClick={() => handleBookSlot(slot._id)}
//          p={2}
//          textAlign="center"
//          w={{ base: "100px", md: "120px" }}
//          h="50px"
//          transition="all 0.2s"
//         >
//          {/* Badge for Discount */}
//          {slot.discount && (
//           <Box position="absolute" top="-8px" left="-8px">
//            <Box
//             as="span"
//             bg="blue.500"
//             color="white"
//             fontSize="10px"
//             fontWeight="bold"
//             borderRadius="full"
//             px={2}
//             py={0.5}
//             shadow="sm"
//            >
//             {slot.discount}% OFF
//            </Box>
//           </Box>
//          )}
//          {/* Slot Time */}
//          <Flex direction="column" align="center" justify="center" h="full">
//           <Text fontSize="sm" fontWeight="bold" color="gray.800" mt={5}>
//            {slot.time}
//           </Text>
//          </Flex>
//         </Box>
//        );
//       })}
//     </Grid>
//    </Box>

//    {/* Next Button */}
//    {selectedTime && (
//     <Flex justify="center" mt={6}>
//      <Button colorScheme="blue" size="lg">
//       Next
//      </Button>
//     </Flex>
//    )}
//   </Box>
//  );
// };

// export default TimeSlotPicker;
