import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartCard from "../UI/CartCardPage";
import { Box, Spinner, Flex, useBreakpointValue } from "@chakra-ui/react";
import {
 addToCart,
 fetchCart,
 removeProductFromCart,
} from "../../redux/cartSlice";
import OriginalSpareParts from "../UI/OriginalSpareParts";
import MobileCartBar from "./MobileCartBar";
import ListingPage from "./ListingPage";

const ProductListingPage = () => {
 const dispatch = useDispatch();
 const { user, loading } = useSelector((state) => state.auth);
 const { cart, couponCodes } = useSelector((state) => state.cart);
 const isMobile = useBreakpointValue({ base: true, md: false });
 const [expandedCard, setExpandedCard] = useState(null); // State to track expanded card

 const toggleShowMore = (productId) => {
  setExpandedCard((prev) => (prev === productId ? null : productId));
 };
 const { currentCar: { _id: currentCarId } = {}, _id: userId = null } =
  user || {};

 const handleAddToCart = (product, ProductType) => {
  const {
   _id: productId,
   subCategoryId: { _id: subCategoryId },
  } = product;

  if (
   (productId && subCategoryId && currentCarId) ||
   ("12345" && userId && ProductType)
  ) {
   dispatch(
    addToCart({ productId, subCategoryId, currentCarId, userId, ProductType })
   );
   dispatch(fetchCart(userId));
  } else {
   console.error("Missing required fields:", {
    productId,
    subCategoryId,
    currentCarId,
    userId,
   });
  }
 };

 // Handle removing items from cart
 const handleRemove = (item) => {
  const productId = item?.productId;
  const subCategoryId = item?.subCategoryId;

  if (productId && subCategoryId) {
   dispatch(
    removeProductFromCart({
     userId: user._id,
     productId,
     subCategoryId,
    })
   );
  } else {
   console.error("Missing productId or subCategoryId!");
  }
 };

 // Check if product is in cart
 const isProductInCart = (productId) => {
  return cart?.items?.some((item) => item.productId === productId);
 };

 const handleQuantityChange = (id, delta) => {};

 return (
  <Flex
   /* Responsive container */
   direction={{ base: "column", md: "row" }}
   w="90%"
   justify="center"
   align="flex-start"
   gap={6}
   m={{ base: "20px auto", md: "30px auto", lg: "40px auto" }}
  >
   {/* Left Section - Categories, Subcategories, and Products */}
   <Box w={{ base: "100%", md: "70%" }}>
    {loading && <Spinner size="lg" />}
    <ListingPage
     expandedCard={expandedCard}
     toggleShowMore={toggleShowMore}
     handleAddToCart={handleAddToCart}
     isProductInCart={isProductInCart}
    />

    <OriginalSpareParts />
   </Box>

   {/* Right Section - Cart */}
   <Box w={{ base: "100%", md: "30%" }} mt={{ base: 6, md: 0 }}>
    {isMobile && (
     <MobileCartBar
      cartItems={cart?.items}
      coupon={couponCodes}
      handleQuantityChange={handleQuantityChange}
      handleRemove={handleRemove}
     />
    )}
    {!isMobile && (
     <CartCard
      cartItems={cart?.items}
      coupon={couponCodes}
      handleQuantityChange={handleQuantityChange}
      handleRemove={handleRemove}
     />
    )}
   </Box>
  </Flex>
 );
};

export default ProductListingPage;
